<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <div class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <searchIdName v-if="model.tipo!=4"  :leitura="(model.id > 0 || guia!=null)" :codigoExterno="true" class="column" table="cliente" :label="$t('SISTEMA.CLIENTE')" 
                                                  :id.sync="model.clienteId" :camposAdicionais="'&unidadeId='+unidadeId" >
                                    </searchIdName>

                                    <b-field v-else :label="$t('SISTEMA.NOME')" >
                                            <b-input type="text" maxlength="200" v-model="model.nome"></b-input>
                                    </b-field>
                                                                            
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <searchIdName class="column" :leitura="!permiteAlterar" table="tipoagendamento" :label="$t('AGENDAMENTO.TIPOAGENDAMENTO')" 
                                    :id.sync="model.tipoAgendamentoId" :nome.sync="model.tipoAgendamentoNome" :camposAdicionais="'&unidadeId='+unidadeId">
                                    </searchIdName>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTO.DATAHORA')">
                                        <b-datetimepicker v-if="permiteAlterar" ref="data"                                                        
                                                        :placeholder="$t('AGENDAMENTO.SELECIONARHORA')"
                                                        v-model="model.dataHora"
                                                        icon="calendar-today"
                                                        :min-datetime="new Date()"
                                                        :timepicker="configTimePicker()"
                                                        horizontal-time-picker>
                                        </b-datetimepicker>
                                        <span v-else >{{ $moment(model.datahora).format("DD/MM/YYYY HH:mm") }}</span>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTO.FUNCIONARIORESPONSAVEL')">
                                        <b-select :disabled="(guia!=null)" expanded v-model="model.funcionarioId">
                                            <option :value="null"></option>
                                            <template v-for="(item, index) in funcionariosAtivos">
                                                <option v-bind:key="index" :value="item.id">
                                                    {{item.nome}}
                                                </option>
                                            </template>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">

                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTO.TIPOAGENDA')">
                                        <b-select expanded v-model="model.tipo">                                            
                                            <template v-for="(item, index) in guiaAgendaTipos">
                                                <option v-bind:key="index" :value="item.codigo">
                                                    {{item.descricao}}
                                                </option>
                                            </template>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                </div>
                            </div>                            
                            <div v-if="!model.semRelatorioConformidade" class="columns">
                                <div class="column">
                                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                                        <b-input type="textarea"
                                                v-model="model.observacao"
                                                @input="$emit('update:observacao', $event)">
                                        </b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div v-show="isAgendamentoDeVistoria()" class="columns" >
                                <div class="column is-12" >
                                <article class="panel">
                                    <b-field>
                                        <b-switch v-model="model.semRelatorioConformidade"  type="is-success">
                                            {{$t('AGENDAMENTO.SEMRELATORIO')}}
                                        </b-switch>                                            
                                    </b-field> 
                                    <b-field v-if="model.semRelatorioConformidade" :label="$t('AGENDAMENTO.SEMRELATORIOMOTIVO')">
                                        <b-input type="textarea" v-model="model.semRelatorioMotivo" >
                                        </b-input>
                                    </b-field>                                    
                                </article>
                                </div>
                                <br>
                            </div>                            
                            <div v-show="isAgendamentoDeVistoria() && !model.semRelatorioConformidade" class="columns" >
                                <b-notification  type="is-info" has-icon icon-size="is-medium" :closable="false" role="alert" >
                                    {{$t('AGENDAMENTO.MENSAGEMCAMPOSOBRIGATORIOVISTTORIA')}}
                                </b-notification> 
                                <br>
                            </div>
                            <div v-show="isAgendamentoDeVistoria()  && !model.semRelatorioConformidade " class="columns" >
                                <div class="column is-12" >
                                <article class="panel">
                                    <b-field>
                                        <b-switch v-model="model.confirmaFotosRede"  type="is-success">
                                            {{$t('AGENDAMENTO.MENSAGEMCONFIRMAFOTOSREDE')}}
                                        </b-switch>                                            
                                    </b-field> 
                                    <div class="column" >
                                        <b-field>
                                            <b-upload
                                            accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                                             multiple expanded drag-drop v-model="model.file" :disabled="model.file && model.file.length==1">
                                                <section class="section">
                                                    <div class="content has-text-centered">
                                                        <p>
                                                            <b-icon icon="upload" size="is-medium"> </b-icon>
                                                        </p>
                                                        <p>{{$t('ANEXOS.ARRASTEARQUIVOS')}}</p>
                                                        <p>({{$t('ANEXOS.APENASPDF')}})</p>
                                                    </div>
                                                </section>
                                            </b-upload>                                          
                                        </b-field>
                                        <div v-if="model.file" class="tags">
                                            <span v-for="(file, index) in model.file"
                                                :key="index" class="tag is-primary" >
                                                {{file.name}}
                                                <button class="delete is-small" type="button" @click="deleteFile(index)">
                                                </button>
                                            </span>                                            
                                        </div>                                          
                                    </div>                                                                                   
                                </article>                                     
                                </div>
                                

                            </div>
                        </div>                        
                    </div>

                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <historico-alteracoes :agendaLog="true" :chave="model.id" v-show="model.id"></historico-alteracoes>
                                <p v-if="model.dataHoraCadatro" class="panel-block">
                                    Agendado em {{ model.dataHoraCadastro | moment('DD/MM/YYYY [às] HH:mm') }}
                                    <br/>
                                    por {{ model.funcionarioNome }}
                                </p>                              
                        </article>
                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.CONFIRMAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import { mapState,mapMutations } from 'vuex'   
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import modalAlteracaoAgenda from '@/components/atendimento/modalAlteracaoAgenda'

    export default {
       
        data() {
            return {
                model: {
                    id: null,
                    clienteId: null,
                    clienteNome: null,
                    tipoAgendamentoId: null,
                    tipoAgendamentoNome: null,
                    unidadeId: null,
                    unidadeNome: null,
                    funcionarioId: null,
                    funcionarioNome: null,
                    guiaId: null,
                    dataHora: null,
                    dataHoraOriginal: null,
                    dataHoraCadastro: null,                    
                    observacao: null,
                    status: null,
                    statusTitle: null,
                    encaixe: false,
                    tipo:null,
                    confirmaFotosRede: false,
                    semRelatorioConformidade: false,
                    semRelatorioMotivo: null
                },
                funcionariosAtivos: [],
                guiaAgendaTipos: [],
                permiteAlterar: true,
                isLoading:false,   
                guia: this.$route.params?.guia ?? null, 
                voltarTela: this.$route.params?.voltarTela ?? null 
            }
        },
        components: {
            titlebar,
            searchIdName,
            historicoAlteracoes
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.ATENDIMENTO'),
                    this.$t('AGENDA.AGENDA'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([                
                'unidadeId',
                'usuario',                 
            ]),
            ...mapMutations('componentes', [
                'HANDLE_RESIZE',                
            ])            
        },
   
        mounted() {
            this.handleResize();
            this.loadAsyncData();
            
            if(this.guia){
                setTimeout(() => {          
                    
                    if(!this.model.id)
                    {
                        this.model.tipo = this.guia.tipoAgenda;
                        this.model.clienteId = this.guia.clienteCodigoExterno;
                        this.model.guiaId = this.guia.id;                    
                        this.model.funcionarioId = (this.guia.tipoAgenda==1) ? this.guia.funcionarioComercialId : this.usuario.funcionarioId;                        
                    }
                    
                },500);
            }
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData()                                                
            },
        },           
        methods: {
            handleResize() {
                this.$store.commit('componentes/HANDLE_RESIZE')
            },            
            configTimePicker(){
                const min = new Date()
                min.setHours(6)
                min.setMinutes(0)
                const max = new Date()
                max.setHours(22)
                max.setMinutes(0)
                return {
                    enableSeconds: false, 
                    hourFormat: '24',
                    minTime: min,
                    maxTime: max
                }
            },
            deleteFile(index){                
                this.model.file.splice(index, 1)            
            },
            isAgendamentoDeVistoria(){
                return (this.model.tipo==2 && this.guia!=null && this.model.tipoAgendamentoNome=="VISTORIA"  );
            },
            isAgendamentoMedicao(){
                return (this.model.tipo==2 && this.guia!=null && this.model.tipoAgendamentoNome=="MEDIÇÃO"  );
            },            
            loadAsyncData() {                
                this.$http.get('/api/search/FuncionariosAtivos?unidadeId='+this.unidadeId)
                .then(res => {
                    if (res.data != null) {
                        this.funcionariosAtivos = res.data
                    }                    
                })

                this.$http.get('/api/search/guiaAgendaTipo')
                .then(res => {
                    if (res.data != null) {
                        this.guiaAgendaTipos = res.data
                    }
                })                

                if (this.$route.params.id) {
                    this.isLoading = true;
                    this.$http.get('/api/agenda/agendamento?id=' + this.$route.params.id)
                        .then(res => {                        
                            this.model = res.data
                            this.model.funcionarioId = this.model.funcionarioResponsavelId;
                            this.model.dataHora = this.$moment(this.model.dataHora).toDate()
                            this.model.dataHoraOriginal = this.$moment(this.model.dataHora).toDate()
                            this.model.observacao = this.model.observacao ? this.model.observacao.replaceAll('\\n','\n') : ''; 
                            this.isLoading = false;
                        })
                }else{

                    if(this.$route.params.tipoAgendamentoId){
                        this.model.tipoAgendamentoId = this.$route.params.tipoAgendamentoId;                    
                    }

                    if(this.$route.params.horario){
                        this.model.dataHora = this.$route.params.horario;                    
                    }    
                                                                    
                }

                if(this.$route.params.permiteAlterar)
                    this.permiteAlterar =  this.$route.params.permiteAlterar;

                if(this.$route.params.encaixe)
                    this.model.encaixe =  this.$route.params.encaixe;                
                            
                this.$forceUpdate();             
            },                       
            onSubmit() {


                //Vistoria e observacao
                if(this.isAgendamentoDeVistoria() && !this.model.semRelatorioConformidade){  

                    if(this.model.observacao ==null || (this.model.observacao !=null && this.model.observacao.length <20)){
                            
                            this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                            type: 'is-warning',
                            hasIcon: true
                            });                        
                            return true;
                    }

                }          
                
                //mEDIÇÃO e observacao
                if(this.isAgendamentoMedicao()){
                    if(this.model.observacao ==null || (this.model.observacao !=null && this.model.observacao.length <20)){
                            
                            this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                            type: 'is-warning',
                            hasIcon: true
                            });                        
                            return true;
                    }                    
                }

                //Vistoria e validar se relatório anexado
                if(this.isAgendamentoDeVistoria()  && !this.model.semRelatorioConformidade){                    
                    if(!this.model.confirmaFotosRede){

                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('AGENDAMENTO.MENSAGEMOBRIGATORIOCONFIRMAFOTOSREDE'),
                            type: 'is-danger',
                            queue: false
                        });          
                        return false;          
                    } 

                    if(this.model.file == null){

                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('AGENDAMENTO.MENSAGEMOBRIGATORIOANEXO'),
                            type: 'is-danger',
                            queue: false
                        });          
                        return false;          
                    }                     

                }
                
                    
                //Vistoria e sem relatório marcado
                if(this.isAgendamentoDeVistoria()  && this.model.semRelatorioConformidade){  

                    if(this.model.semRelatorioMotivo ==null || (this.model.semRelatorioMotivo !=null && this.model.semRelatorioMotivo.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('AGENDAMENTO.SEMRELATORIOMOTIVO'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }       

                }

                if(this.model.tipo !=4 && !this.model.clienteId){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDAMENTO.MENSAGEMCLIENTE'),
                        type: 'is-danger',
                        queue: false
                    });          
                    return false;          
                } 

                if(this.model.tipo ==4 && !this.model.nome){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDAMENTO.MENSAGEMCLIENTE'),
                        type: 'is-danger',
                        queue: false
                    });          
                    return false;          
                }                 

                if(!this.model.tipoAgendamentoId){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDAMENTO.MENSAGEMTIPOAGENDAMENTO'),
                        type: 'is-danger',
                        queue: false
                    });                    
                    return false;
                } 
                
                if(!this.model.dataHora){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDAMENTO.MENSAGEMDATAHORA'),
                        type: 'is-danger',
                        queue: false
                    }); 
                    
                    return false;
                }    
                
                
                if(!this.model.tipo){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDAMENTO.MENSAGEMTIPOAGENDA'),
                        type: 'is-danger',
                        queue: false
                    }); 
                    
                    return false;
                }     
                
                
                if(!this.model.funcionarioId){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDAMENTO.MENSAGEMRESPONSAVEL'),
                        type: 'is-danger',
                        queue: false
                    }); 
                    
                    return false;
                }   
                
                if(this.model.dataHora.getHours() == 0){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDAMENTO.MENSAGEMHORA'),
                        type: 'is-danger',
                        queue: false
                    }); 
                    
                    return false;
                }                  

                //Verificar se houve alteracao no agendamento/data                
                if(this.model.dataHoraOriginal){                    
                    if(this.model.dataHoraOriginal.getDay() != this.model.dataHora.getDay() || this.model.dataHoraOriginal.getMonth() != this.model.dataHora.getMonth() 
                    || this.model.dataHoraOriginal.getFullYear() != this.model.dataHora.getFullYear() || this.model.dataHoraOriginal.getMinutes() != this.model.dataHora.getMinutes() 
                    || this.model.dataHoraOriginal.getHours() != this.model.dataHora.getHours() ){

                        this.$buefy.modal.open({
                            parent: this,
                            component: modalAlteracaoAgenda,
                            events: {
                                salvarAgendamento: this.salvarAgendamento
                            },
                            hasModalCard: true,
                            trapFocus: true
                        })
                    }else{
                        this.salvarAgendamento();
                    }

                }else{
                    this.salvarAgendamento();
                }                
                
            },
            salvarAgendamento(motivoAlteracao){
                this.isLoading = true;
                const params = [];
                if (this.model.id) params.push(`id=${this.model.id}`);

                if(this.model.tipo !=4)
                    params.push(`codigoExterno=${this.model.clienteId}`);

                if(this.model.tipo==4){
                    this.model.clienteId = null;
                    this.model.nome = this.model.nome.toUpperCase();
                    params.push(`nome=${this.model.nome}`);
                }else{
                    this.model.nome = null;
                    params.push(`codigoExterno=${this.model.clienteId}`);
                    
                }    


                params.push(`tipoAgendamentoId=${this.model.tipoAgendamentoId}`);
                
                if(this.model.funcionarioId == null) this.model.funcionarioId = 0;
                    params.push(`funcionarioId=${this.model.funcionarioId}`);

                params.push(`encaixe=${this.model.encaixe}`);
                params.push(`tipo=${this.model.tipo}`);

                if (this.model.guiaId) params.push(`guiaId=${this.model.guiaId}`);

                params.push(`datahora=${this.$moment(this.model.dataHora).format('YYYY-MM-DDTHH:mm:ss')}`);
                params.push(`observacao=${this.model.observacao ? this.model.observacao : ''}`);
                if (this.unidadeId != null)
                    params.push(`unidadeId=${this.unidadeId}`)

                if (motivoAlteracao!= null)
                    params.push(`motivo=${motivoAlteracao}`)  
                
                if(this.model.semRelatorioMotivo !=null )
                    params.push(`semRelatorioMotivo=${this.model.semRelatorioMotivo}`) ;
                                                    

                const url = this.model.id
                    ? `/api/agenda/agendaEditar?${params.join('&')}`
                    : `/api/agenda/agendaincluir?${params.join('&')}`
                
                               
                this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                        if (data.mensagem != null && data.mensagem != "") {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: data.mensagem,
                                type: 'is-danger',
                                queue: false
                            })
                        }else{

                            if(this.isAgendamentoDeVistoria()  && !this.model.semRelatorioConformidade){
                                this.incluirAnexo(this.model.file);
                            }
                            
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('SISTEMA.ERROSALVAR'),
                            type: 'is-danger',
                            queue: false
                        })
                    }).finally(() => {                        
                        this.isLoading = false;
                        if(this.voltarTela){
                            this.$router.push({ name: this.voltarTela});
                            
                        }else{
                            this.$router.back();
                        }
                    }); 
            },
            incluirAnexo(fileList) {
                const formData = new FormData()
                formData.append('id', this.guia.id);
                formData.append('type', 'guia');
                formData.append('etapa', 'vistoria');
                

                Array
                    .from(Array(fileList.length).keys())
                    .map(x => {                        
                        formData.append('anexo', fileList[x], fileList[x].name);
                    });

                this.uploadingAnexo = 0;
                this.isLoading = true;                    
                this.$http.post('/api/atendimento/anexoGuia', formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    })                    
                    this.isLoading = false;                    
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                });
            },                  
        }        
    }
</script>