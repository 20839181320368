<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>

        <div class="columns">
 

            <div class="column is-3">
                <div v-if="isInRole('agenda-consultar')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('SISTEMA.AGENDA')}}
                    </p>
                    <p v-if="agendaHoje.length > 0" class="panel-block">
                         {{$t('ATENDIMENTO.CLIENTESAGENDADOS')}}
                    </p>
                    <p v-if="agendaHoje.length == 0" class="panel-block">
                         {{$t('ATENDIMENTO.NENHUMCLIENTEAGENDADO')}}
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable m-0">     
                        <tbody>
                            <tr v-for="(item, index) in agendaHoje" v-bind:key="index">
                                <td> 
                                    <span class="is-size-7 is-flex">
                                        <b-icon :style="tipoAgendamentoStyle(item)"  size="is-small" icon="square-rounded"></b-icon>
                                        <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                            <template v-slot:content>
                                                <b>Responsável: </b> {{item.funcionarioResponsavelNome}}
                                            </template>                                             
                                            <span class="is-size-7">{{item.tipoAgendamentoNome}} - {{item.clienteNome ?? item.nome}} </span>
                                        </b-tooltip>
                                    </span>                                    
                                </td>
                                <td> <span class="tag is-info">{{item.dataHora | moment('HH:mm')}}</span> </td>
                                <td> 
                                    <router-link v-if="item.guiaId && item.guiaId >0" :to="{ name: 'guia', params: { id: item.guiaId}}" 
                                    class="button is-small is-primary" title="Abrir Atendimento">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </router-link>    
                                </td>
                            </tr>
                        </tbody>
                    </table>
     
                    <div >
                        <router-link v-if="isInRole('atendimento-agendamento')"  :to="{ name: 'agenda', params: {tipo: 2,filtrarTodos:true,voltarTela: 'venda'  } }" class="button is-link is-outlined is-fullwidth">
                            {{$t('ATENDIMENTO.ABRIRCALENDARIO')}}
                        </router-link>

                      
                    </div>
                </div>
            </div>                    
           
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderRevisarEfetivacao==2 ? orderRevisarEfetivacao= 0 : orderRevisarEfetivacao++; ordenar('RevisarEfetivacao')">
                        <span><b-icon :icon="iconeOrderRevisarEfetivacao"  size="is-small"> </b-icon></span>
                        {{$t('VENDAS.REVISAO')}} 
                        ({{ listaVendasRevisao.length}})
                        <span v-if="listaVendasRevisao.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasRevisao.filter(x=>x.atraso).length }}</span>                          
                    </p>

                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasRevisao" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}} </span>
                                    <span>
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>  
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                         

                                    </span>                                    
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"> 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('vendas-revisao')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link> 
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                                  
                                    </div>      
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>           
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>
                    
                </div>
            </div>
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderValidarEfetivacao==2 ? orderValidarEfetivacao= 0 : orderValidarEfetivacao++; ordenar('ValidarEfetivacao')">
                        <span><b-icon :icon="iconeOrderValidarEfetivacao"  size="is-small"> </b-icon></span>
                        {{$t('VENDAS.VALIDAR')}} 
                        <span v-if="!isVendedor()"> ({{listaVendasValidacao?.length}}) </span>
                        <span v-else> ( {{listaVendasValidacao?.length}} / {{totalVendasValidacao}} ) </span>
                        <span v-if="listaVendasValidacao.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasValidacao.filter(x=>x.atraso).length }}</span>                          
                    </p>
                    <table class="table is-fullwidth">
                        <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasValidacao" v-bind:key="index">
                            <td>
                                <span>{{item.clienteCodigoExterno}} 
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>                                      
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                                    
                                    </span>                                                                                       
                            </td>                                
                            <td class="pl-0 pr-0">
                                <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                    <template v-slot:content>
                                        <span v-if="item.codigoContrato>0">
                                            <b>CONTRATO: </b> {{item.codigoContrato}}
                                            <br>
                                        </span>                                        
                                        <span>
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}                                            
                                        </span>                                         
                                    </template>                                             
                                    <span class="is-size-7 highlight">{{item.clienteNome}}</span>
                                </b-tooltip>
                            </td>
                            <td>
                                <div class="is-inline-flex">
                                    <router-link v-if="isInRole('vendas-validacao')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </router-link>        
                                    <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                    :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                
                                </div>
                                <br>                                
                                <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>

                            </td>
                        </tr>
                    </table>  
                    
                </div>
            </div>
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderFinanceiro==2 ? orderFinanceiro= 0 : orderFinanceiro++; ordenar('Financeiro')">
                        <span><b-icon :icon="iconeOrderFinanceiro"  size="is-small"> </b-icon></span>
                        {{$t('VENDAS.FINANCEIRO')}} 
                        ({{ listaVendasFinanceiro.length}})
                        <span v-if="listaVendasFinanceiro.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasFinanceiro.filter(x=>x.atraso).length }}</span>                          
                    </p>

                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasFinanceiro" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}} 
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>      
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                                                                    
                                    </span>
                                                                       
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>                                            
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('vendas-financeiro')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>  
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                    
                                    </div>
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>                    

                    
                </div>
            </div>

                

        </div>
        <div class="columns">

            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderEletros==2 ? orderEletros= 0 : orderEletros++; ordenar('Eletros')">
                        <span><b-icon :icon="iconeOrderEletros"  size="is-small"> </b-icon></span>                     
                      {{$t('VENDAS.ELETROS')}} 
                      ({{ listaVendasEletro.length}})
                      <span v-if="listaVendasEletro.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasEletro.filter(x=>x.atraso).length }}</span>                          
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasEletro" v-bind:key="index">
                                
                                <td>

                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon> 
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                                                                         
                                    </span>                                    

                                </td>                                 
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>                                            
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                            
                                            <span v-if="item.dataHoraBloqueadoEletro">
                                                <b>PRAZO ELETROS: </b> {{ $moment(item.dataHoraBloqueadoEletro).format("DD/MM/YYYY")}}                                                
                                            </span>                                               
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('vendas-eletro')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                      
                                    </div>
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>                      

                    
                </div>
            </div>   
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderAssinatura==2 ? orderAssinatura= 0 : orderAssinatura++; ordenar('Assinatura')">
                        <span><b-icon :icon="iconeOrderAssinatura"  size="is-small"> </b-icon></span>                                          
                        {{$t('VENDAS.ASSINATURA')}} 
                        ({{ listaVendasAssinatura.length}})
                        <span v-if="listaVendasAssinatura.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasAssinatura.filter(x=>x.atraso).length }}</span>                          
                    </p>
                    <b-collapse class="panel m-0" animation="slide" :open="false"  v-model="collapses[0]">
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Vendedor ({{  listaVendasAssinatura.filter(x=> (x.dataHoraAssinaturaGerente != null || !x.uploadProjeto )).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasAssinatura.filter(x=>x.dataHoraAssinaturaGerente != null || !x.uploadProjeto)" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>   
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                                                                      
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>                                            
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>  
                                            <span v-if="item.dataHoraBloqueadoAssinatura">
                                                <b>PRAZO ASSINATURA: </b> {{ $moment(item.dataHoraBloqueadoAssinatura).format("DD/MM/YYYY")}}                                                
                                            </span>                                                                                                    
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>

                                    <div  v-if="isInRole('vendas-assinatura') && item.guiaStatus ==1" class="is-inline-flex">
                                        <router-link :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>         
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                     
                                    </div>
                                    <div v-else class="is-inline-flex">                                        
                                        <router-link :to="{ name: 'guia', params: { id: item.guiaId, guiaList: true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>                                            
                                        <b-button @click="mostraMensagemAguardandoAlteracao()" 
                                        class="is-small has-text-danger" title="Aguardando finalizar alteração"> <b-icon icon="lock-alert-outline" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                                                                                       
                                    </div>
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                        </table>                            
                    </b-collapse>
                    <b-collapse class="panel m-0" animation="slide" :open="false" v-model="collapses[1]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Gerente ({{  listaVendasAssinatura.filter(x=>x.dataHoraAssinaturaGerente == null && x.uploadProjeto).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasAssinatura.filter(x=>x.dataHoraAssinaturaGerente == null && x.uploadProjeto)" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                                                                          
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>                                            
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>  
                                            <span v-if="item.dataHoraBloqueadoAssinatura">
                                                <b>PRAZO ASSINATURA: </b> {{ $moment(item.dataHoraBloqueadoAssinatura).format("DD/MM/YYYY")}}                                                
                                            </span>                                                                                                    
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>

                                    <div v-if="isInRole('vendas-assinatura-gerente') && item.guiaStatus ==1"  class="is-inline-flex">
                                        <router-link  :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>         
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                     
                                    </div>
                                    <div v-else class="is-inline-flex">                                        
                                        <router-link :to="{ name: 'guia', params: { id: item.guiaId, guiaList: true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>                                            
                                        <b-button @click="mostraMensagemAguardandoAlteracao()" 
                                        class="is-small has-text-danger" title="Aguardando finalizar alteração"> <b-icon icon="lock-alert-outline" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                                                                                       
                                    </div>                                    
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                 
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                        </table>                            
                    </b-collapse>                                           

                    
                </div>
            </div>  
            

            
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderMedidor==2 ? orderMedidor= 0 : orderMedidor++; ordenar('Medidor')">
                        <span><b-icon :icon="iconeOrderMedidor"  size="is-small"> </b-icon></span>                                                         
                    {{$t('VENDAS.MEDIDOR')}} 
                    ({{ listaVendasMedidor.length}})
                    <span v-if="listaVendasMedidor.filter(x=>x.atraso  && x.status == 0).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasMedidor.filter(x=>x.atraso).length }}</span>                          
                    </p>

                    <b-collapse class="panel m-0" animation="slide" :open="false" v-model="collapses[2]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Medições ({{  listaVendasMedidor.filter(x=>x.dataHoraMedidorFlowall == null && x.status == 0).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                            <tbody>
                                <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasMedidor.filter(x=>x.dataHoraMedidorFlowall == null  && x.status == 0)" v-bind:key="index">
                                    
                                    
                                    <td>
                                        <span>{{item.clienteCodigoExterno}}
                                            <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                            size="is-small"                                    
                                                            :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                            type="is-link"></b-icon>                                          
                                            <b-icon v-if="item.tipo == 2"
                                                            icon="cog"
                                                            size="is-small"                                    
                                                            :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                            <b-icon v-if="item.tipo == 3"
                                                            icon="tools"
                                                            size="is-small"                                    
                                                            :title="$t('VENDAS.MOSTRUARIO')"></b-icon>  
                                            <b-icon v-if="item.dataHoraAgendamentoMedidor"
                                                            icon="calendar"
                                                            size="is-small"
                                                            type="is-info"                                    
                                                            :title="$t('VENDAS.AGENDAMENTOENTREGAREALIZADO')"></b-icon>
                                            <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                    <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                            </span>                                                                                                                                                                       
                                        </span> 
                                    </td>                                
                                    <td class="pl-0 pr-0">
                                        <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                            <template v-slot:content>
                                                <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                                
                                                <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                                <span>                                                
                                                    <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                        <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                    </small>                                            
                                                </span> 
                                                <span v-if="item.dataHoraAgendamentoMedidor">       
                                                    <br><b>MEDIÇÃO: </b>  {{$moment(item.dataHoraAgendamentoMedidor).format('DD/MM/YYYY')}}                                                
                                                </span>                                                                                         
                                            </template>                                             
                                            <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                        </b-tooltip>
                                    </td>                                
                                    <td>
                                        <div class="is-inline-flex">
                                            <router-link v-if="isInRole('vendas-medidor') " :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                                <b-icon icon="pencil" size="is-small"></b-icon>
                                            </router-link>
                                            <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                            :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                      
                                        </div>
                                        <br>                                
                                        <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                    </td>                                
                                </tr>  
                            </tbody>                                                 
                        </table>                          
                    </b-collapse> 

                    <b-collapse class="panel  m-0" animation="slide" :open="false" v-model="collapses[3]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title is-size-7">
                                    Flowall/Inside ({{  listaVendasMedidor.filter(x=>x.dataHoraMedidorFlowall != null  && x.status == 0).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                            <tbody>
                                <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasMedidor.filter(x=>x.dataHoraMedidorFlowall != null  && x.status == 0)" v-bind:key="index">
                                    
                                    
                                    <td>
                                        <span>{{item.clienteCodigoExterno}}
                                            <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                            size="is-small"                                    
                                                            :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                            type="is-link"></b-icon>                                          
                                            <b-icon v-if="item.tipo == 2"
                                                            icon="cog"
                                                            size="is-small"                                    
                                                            :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                            <b-icon v-if="item.tipo == 3"
                                                            icon="tools"
                                                            size="is-small"                                    
                                                            :title="$t('VENDAS.MOSTRUARIO')"></b-icon>  
                                            <b-icon v-if="item.dataHoraAgendamentoMedidor"
                                                            icon="calendar"
                                                            size="is-small"
                                                            type="is-info"                                    
                                                            :title="$t('VENDAS.AGENDAMENTOENTREGAREALIZADO')"></b-icon> 
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                       
                                        </span>                                                                                                                                                                      
                                        </span> 
                                    </td>                                
                                    <td class="pl-0 pr-0">
                                        <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                            <template v-slot:content>
                                                <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                                
                                                <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                                <span>                                                
                                                    <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                        <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                    </small>                                            
                                                </span> 
                                                <span v-if="item.dataHoraAgendamentoMedidor">       
                                                    <br><b>MEDIÇÃO: </b>  {{$moment(item.dataHoraAgendamentoMedidor).format('DD/MM/YYYY')}}                                                
                                                </span>   
                                                <span v-if="item.dataHoraMedidorFlowall">
                                                    <br>
                                                    <b>PRÓXIMO PRAZO: </b> {{ $moment(item.dataHoraMedidorFlowall).format("DD/MM/YYYY")}}                                                
                                                </span>                                                                                                                                        
                                            </template>                                             
                                            <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                        </b-tooltip>
                                    </td>                                
                                    <td>
                                        <div class="is-inline-flex">
                                            <router-link v-if="isInRole('vendas-medidor') " :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                                <b-icon icon="pencil" size="is-small"></b-icon>
                                            </router-link>
                                            <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                            :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>
                                            <b-button v-if="isInRole('vendas-medidor') && item.atraso" @click="confirmarAlteracaoPrazoFlowall(item)" 
                                            class="is-small has-text-danger" title="Alterar Prazo ?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                                        </div>
                                        <br>                                
                                        <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                    </td>                                
                                </tr>  
                            </tbody>                                                 
                        </table>                          
                    </b-collapse>  
                    <b-collapse class="panel  m-0" animation="slide" :open="false" v-model="collapses[4]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title is-size-7">
                                    Vistoria - Entrega Futura ({{  listaVendasMedidor.filter(x=>x.status == 2 && x.dataHoraVistoriaMedicao!=null).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                            <tbody>
                                <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasMedidor.filter(x=>x.status == 2  && x.dataHoraVistoriaMedicao!=null)" v-bind:key="index">
                                    
                                    
                                    <td>
                                        <span>{{item.clienteCodigoExterno}}
                                            <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                            size="is-small"                                    
                                                            :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                            type="is-link"></b-icon>                                          
                                            <b-icon v-if="item.tipo == 2"
                                                            icon="cog"
                                                            size="is-small"                                    
                                                            :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                            <b-icon v-if="item.tipo == 3"
                                                            icon="tools"
                                                            size="is-small"                                    
                                                            :title="$t('VENDAS.MOSTRUARIO')"></b-icon> 
                                            <b-icon v-if="item.dataHoraAgendamentoMedidor"
                                                            icon="calendar"
                                                            size="is-small"
                                                            type="is-info"                                    
                                                            :title="$t('VENDAS.AGENDAMENTOENTREGAREALIZADO')"></b-icon>                                                               
                                            <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                       
                                            </span>                                                                                                                                                                      
                                        </span> 
                                    </td>                                
                                    <td class="pl-0 pr-0">
                                        <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                            <template v-slot:content>
                                                <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                                
                                                <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                                <span>                                                
                                                    <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                        <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                    </small>                                            
                                                </span>  
                                                <span v-if="item.dataHoraAgendamentoMedidor">       
                                                    <br><b>MEDIÇÃO: </b>  {{$moment(item.dataHoraAgendamentoMedidor).format('DD/MM/YYYY')}}                                                
                                                </span>                                                                                                                                                                                       
                                            </template>                                             
                                            <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                        </b-tooltip>
                                    </td>                                
                                    <td>
                                        <div class="is-inline-flex">
                                            <router-link v-if="isInRole('vendas-medidor') " :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true, emVistoriaMedicao:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                                <b-icon icon="pencil" size="is-small"></b-icon>
                                            </router-link>
                                            <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                            :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>
                                        </div>
                                        <br>                                
                                        <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                    </td>                                
                                </tr>  
                            </tbody>                                                 
                        </table>                          
                    </b-collapse>                     
                                                           
                    

                    
                </div>
            </div>
            
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderConferenciaEmEspera==2 ? orderConferenciaEmEspera= 0 : orderConferenciaEmEspera++; ordenar('ConferenciaEmEspera')">
                        <span><b-icon :icon="iconeOrderConferenciaEmEspera"  size="is-small"> </b-icon></span>                                        
                        <span class="is-size-7">{{$t('VENDAS.CONFERENCIAESPERA')}} </span>
                        
                        <span v-if="!isVendedor()"> ({{listaVendasConferenciaAutorizada?.length}}) </span>
                        <span v-else> ( {{listaVendasConferenciaAutorizada?.length}} / {{totalVendasConferenciaAutorizada}} ) </span>

                        <span v-if="listaVendasConferenciaAutorizada.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasConferenciaAutorizada.filter(x=>x.atraso).length }}</span>                          
                        <span v-if="isInRole('atendimento-unificar')" class="is-pulled-right ">
                            <b-button v-if="!habilitaSelecionarUnificarConferenciaEmEspera"
                             :title="$t('POSVENDAS.UNIFICARATENDIMENTO')"  class="is-warning" 
                            icon-left="merge" size="is-small"  @click="selecionarAtendimentosUnificar('ConferenciaEmEspera')"></b-button>      
                            
                            <b-button v-if="habilitaSelecionarUnificarConferenciaEmEspera" :title="$t('POSVENDAS.CONFIRMARUNIFICACAO')"  class="is-success" 
                            size="is-small"  icon-left="check"
                            @click="confirmarUnificar('ConferenciaEmEspera',false)"></b-button>  

                            <b-button v-if="habilitaSelecionarUnificarConferenciaEmEspera" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            size="is-small" icon-left="close"
                            @click="cancelarSelecionarUnificar('ConferenciaEmEspera')"></b-button>                                                                           
                        </span>                          
                    </p>
                    <router-link v-if="isInRole('vendas-entregas-futuras')"  :to="{ name: 'guia-list', query: { tipo:'entregafutura' }  }" class="button is-link is-outlined is-fullwidth">
                            {{$t('VENDAS.ENTREGAFUTURALISTAR')}}
                             <span class="ml-1 tag is-info" title="Aguardando prazo">{{ listaVendasEntregaFutura.length }}</span>                              
                            <span v-if="listaVendasEntregaFutura.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasEntregaFutura.filter(x=>x.atraso).length }}</span>                                                                    
                        </router-link>                    
                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasConferenciaAutorizada" v-bind:key="index">
                                
                                <td v-if="habilitaSelecionarUnificarConferenciaEmEspera">
                                    <b-checkbox  @input="changedUnificar(item)"
                                    size="is-small" type="is-info"  v-model="item.unificarAtendimento"></b-checkbox >                                    
                                </td>                                
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon> 
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                            <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                      
                                        </span>                                                                                                         
                                    </span> 
                                </td>                                  
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>                                            
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{$moment(item.prazoRestanteData[index]).format("DD/MM/YYYY")}}  <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PLANTAS: </b> {{$moment(item.prazoRestantePlantaData).format("DD/MM/YYYY")}}  <b> - FALTAM:</b>  {{item.prazoRestantePlanta}} DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small> 
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>QTDE AMBIENTES: </b> {{item.quantidadeAmbientes}}
                                                </small>                                                                                             
                                            </span>                                                                                            
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('vendas-conferenciautorizada') " :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                              
                                    </div>
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>   

                    
                </div>
            </div>
         
         

        </div>

        <div class="columns">

            
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderConferenciaEmExecucao==2 ? orderConferenciaEmExecucao= 0 : orderConferenciaEmExecucao++; ordenar('ConferenciaEmExecucao')">
                        <span><b-icon :icon="iconeOrderConferenciaEmExecucao"  size="is-small"> </b-icon></span>
                        <span class="is-size-7">{{$t('VENDAS.CONFERENCIAEXECUCAO')}}</span>
                        <span v-if="!isConferente()"> ({{listaVendasConferenciaConcluida?.length}}) </span>
                        <span v-else> ( {{listaVendasConferenciaConcluida?.length}} / {{totalVendasConferenciaConcluida}} ) </span>  
                        <span v-if="listaVendasConferenciaConcluida.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasConferenciaConcluida.filter(x=>x.atraso).length }}</span>                                                                    

                        <span v-if="isInRole('atendimento-unificar')" class="is-pulled-right ">
                            <b-button v-if="!habilitaSelecionarUnificarConferenciaEmExecucao"
                             :title="$t('POSVENDAS.UNIFICARATENDIMENTO')"  class="is-warning" 
                            icon-left="merge" size="is-small"  @click="selecionarAtendimentosUnificar('ConferenciaEmExecucao')"></b-button>      
                            
                            <b-button v-if="habilitaSelecionarUnificarConferenciaEmExecucao" :title="$t('POSVENDAS.CONFIRMARUNIFICACAO')"  class="is-success" 
                            size="is-small"  icon-left="check"
                            @click="confirmarUnificar('ConferenciaEmExecucao',true)"></b-button>  

                            <b-button v-if="habilitaSelecionarUnificarConferenciaEmExecucao" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            size="is-small" icon-left="close"
                            @click="cancelarSelecionarUnificar('ConferenciaEmExecucao')"></b-button>                                                                           
                        </span>   
                                                
                    </p>
                    <router-link v-if="isInRole('vendas-conferenciaconcluida') " :to="{ name: 'guia-list', query: { tipo:'conferenciasexecucao' }  }" class="button is-link is-outlined is-fullwidth">
                            {{$t('VENDAS.CONFERENCIASEXECUCAO')}}                                                         
                    </router-link>                     
                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasConferenciaConcluida" v-bind:key="index">
                                
                                <td v-if="habilitaSelecionarUnificarConferenciaEmExecucao">
                                    <b-checkbox  @input="changedUnificar(item)"
                                    size="is-small" type="is-info"  v-model="item.unificarAtendimento"></b-checkbox >                                    
                                </td>                                    
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>  
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                                                                        
                                    </span> 
                                </td>                                 
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>                                            
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{$moment(item.prazoRestanteData[index]).format("DD/MM/YYYY")}}  <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PLANTAS: </b> {{$moment(item.prazoRestantePlantaData).format("DD/MM/YYYY")}}<b> - FALTAM:</b>  {{item.prazoRestantePlanta}} DIAS 
                                                </small>                                            
                                            </span> 
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}}
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b>   {{$moment(item.prazoRestanteVendaData).format("DD/MM/YYYY")}} <b> - FALTAM:</b>  {{item.prazoRestanteVenda}} DIAS 
                                                </small>                                            
                                            </span>                                                                                                                                    
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td  v-if="!isConferente()">
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('vendas-conferenciaconcluida') " :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                                                                      
                                        <b-button v-if="isInRole('vendas-inserir-entregafutura') && item.tipo==1 && item.dataHoraVistoriaMedicao==null " class="is-small has-text-info" @click="confirmarInserirEntregaFuturaVenda(item.id)"
                                        title="Entrega Futura?"> <b-icon icon="clock" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                                                                                                               

                                        <b-button v-if="isInRole('vendas-conferenciaconcluida-prazo') && item.atraso" @click="confirmarAlteracaoPrazoConferente(item)" 
                                        class="is-small has-text-danger" title="Alterar Prazo do Conferente?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                        

                                    </div>
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>

                                </td>    
                                 <td  v-else>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('vendas-conferenciaconcluida') && item.funcionarioConferenteId == usuario.funcionarioId " :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                                                                      
                                        <b-button v-if="isInRole('vendas-inserir-entregafutura')  && item.dataHoraVistoriaMedicao==null " class="is-small has-text-info" @click="confirmarInserirEntregaFuturaVenda(item.id)"
                                        title="Entrega Futura?"> <b-icon icon="clock" size="is-small"></b-icon></b-button>  
                                        <b-button v-if="isInRole('vendas-conferenciaconcluida-prazo') && item.atraso" @click="confirmarAlteracaoPrazoConferente(item)" 
                                        class="is-small has-text-danger" title="Alterar Prazo do Conferente?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                
                                    </div>      
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                                                                                                                                                                                                                                                                                                                                                                                                           

                                 </td>                            
                            </tr>  
                        </tbody>                                                 
                    </table>   

                    
                </div>
            </div>               
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderEnvioProducao==2 ? orderEnvioProducao= 0 : orderEnvioProducao++; ordenar('EnvioProducao')">
                        <span><b-icon :icon="iconeOrderEnvioProducao"  size="is-small"> </b-icon></span>
                      {{$t('VENDAS.ENVIOPRODUCAO')}} 
                    ({{ listaVendasEnvioProducao.length}})
                    <span v-if="listaVendasEnvioProducao.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasEnvioProducao.filter(x=>x.atraso).length }}</span>                                                                    

                    <span v-if="isInRole('atendimento-unificar')" class="is-pulled-right ">
                            <b-button v-if="!habilitaSelecionarUnificarEnvioProducao"
                             :title="$t('POSVENDAS.UNIFICARATENDIMENTO')"  class="is-warning" 
                            icon-left="merge" size="is-small"  @click="selecionarAtendimentosUnificar('EnvioProducao')"></b-button>      
                            
                            <b-button v-if="habilitaSelecionarUnificarEnvioProducao" :title="$t('POSVENDAS.CONFIRMARUNIFICACAO')"  class="is-success" 
                            size="is-small"  icon-left="check"
                            @click="confirmarUnificar('EnvioProducao',true)"></b-button>  

                            <b-button v-if="habilitaSelecionarUnificarEnvioProducao" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            size="is-small" icon-left="close"
                            @click="cancelarSelecionarUnificar('EnvioProducao')"></b-button>                                                                           
                        </span>                     
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasEnvioProducao" v-bind:key="index">
                                
                                <td v-if="habilitaSelecionarUnificarEnvioProducao">
                                    <b-checkbox  @input="changedUnificar(item)"
                                    size="is-small" type="is-info"  v-model="item.unificarAtendimento"></b-checkbox >                                    
                                </td>                                    
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                                                                          
                                    </span> 
                                </td>                                  
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>                                            
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{$moment(item.prazoRestanteData[index]).format("DD/MM/YYYY")}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PLANTAS: </b> {{$moment(item.prazoRestantePlantaData).format("DD/MM/YYYY")}}<b> - FALTAM:</b>  {{item.prazoRestantePlanta}} DIAS 
                                                </small>                                            
                                            </span> 
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}}
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b>  {{$moment(item.prazoRestanteVendaData).format("DD/MM/YYYY")}} <b> - FALTAM:</b>  {{item.prazoRestanteVenda}} DIAS 
                                                </small>                                            
                                            </span>                                                                                                                                    
                                        </template>                                              
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('vendas-envioproducao') " :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                            :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                                                                  
                                    </div> 
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                                           
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>   

                    
                </div>
            </div>

            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderFinalizarEFinance==2 ? orderFinalizarEFinance= 0 : orderFinalizarEFinance++; ordenar('FinalizarEFinance')">
                        <span><b-icon :icon="iconeOrderFinalizarEFinance"  size="is-small"> </b-icon></span>
                      {{$t('VENDAS.FINALIZAREFINANCEVENDA')}} 
                    ({{ listaVendasFinalizarEfinance.length}})
                    <span v-if="listaVendasFinalizarEfinance.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasFinalizarEfinance.filter(x=>x.atraso).length }}</span>                                                                    

                    <span v-if="isInRole('atendimento-unificar')" class="is-pulled-right ">
                            <b-button v-if="!habilitaSelecionarUnificarFinalizarEfinance"
                             :title="$t('POSVENDAS.UNIFICARATENDIMENTO')"  class="is-warning" 
                            icon-left="merge" size="is-small"  @click="selecionarAtendimentosUnificar('FinalizarEfinance')"></b-button>      
                            
                            <b-button v-if="habilitaSelecionarUnificarFinalizarEfinance" :title="$t('POSVENDAS.CONFIRMARUNIFICACAO')"  class="is-success" 
                            size="is-small"  icon-left="check"
                            @click="confirmarUnificar('FinalizarEfinance',true)"></b-button>  

                            <b-button v-if="habilitaSelecionarUnificarFinalizarEfinance" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            size="is-small" icon-left="close"
                            @click="cancelarSelecionarUnificar('FinalizarEfinance')"></b-button>                                                                           
                        </span>                      
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasFinalizarEfinance" v-bind:key="index">
                                
                                <td v-if="habilitaSelecionarUnificarFinalizarEfinance">
                                    <b-checkbox  @input="changedUnificar(item)"
                                    size="is-small" type="is-info"  v-model="item.unificarAtendimento"></b-checkbox >                                    
                                </td>             

                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon> 
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                                                                         
                                    </span> 
                                </td>                                 
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>                                            
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{$moment(item.prazoRestanteData[index]).format("DD/MM/YYYY")}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PLANTAS: </b> {{$moment(item.prazoRestantePlantaData).format("DD/MM/YYYY")}}<b> - FALTAM:</b>  {{item.prazoRestantePlanta}} DIAS 
                                                </small>                                            
                                            </span> 
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}}
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> {{$moment(item.prazoRestanteVendaData).format("DD/MM/YYYY")}} <b> - FALTAM:</b>  {{item.prazoRestanteVenda}} DIAS 
                                                </small>                                            
                                            </span>                                                                                                                                    
                                        </template>                                            
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('vendas-finalizarefinance') " :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                            :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                                                                                                          
                                    </div>
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>   

                    
                </div>
            </div>

            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderPlantas==2 ? orderPlantas= 0 : orderPlantas++; ordenar('Plantas')">
                        <span><b-icon :icon="iconeOrderPlantas"  size="is-small"> </b-icon></span>
                      {{$t('VENDAS.PLANTAS')}} 
                    ({{ listaVendasPlantas.length}})
                    <span v-if="listaVendasPlantas.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaVendasPlantas.filter(x=>x.atraso).length }}</span>                                                                    
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaVendasPlantas" v-bind:key="index">
                                
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>
                                        <span v-if="item.status == 2" class="cp ml-2 tag is-light">
                                                <b :title="$t('GUIALIST.ENTREGAFUTURA')" class="has-text-info is-size-7">Futura</b>                                                                 
                                        </span>                                                                                                          
                                    </span> 
                                </td>                                 
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                <b>CONTRATO: </b> {{item.codigoContrato}}
                                                <br>
                                            </span>                                            
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{$moment(item.prazoRestanteData[index]).format("DD/MM/YYYY")}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PLANTAS: </b> {{$moment(item.prazoRestantePlantaData).format("DD/MM/YYYY")}}<b> - FALTAM:</b>  {{item.prazoRestantePlanta}} DIAS 
                                                </small>                                            
                                            </span> 
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}}
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> {{$moment(item.prazoRestanteVendaData).format("DD/MM/YYYY")}} <b> - FALTAM:</b>  {{item.prazoRestanteVenda}} DIAS 
                                                </small>                                            
                                            </span>                                                                                                                                    
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('vendas-plantas') " :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                            :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                                                                                                                                                  
                                    </div>
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>   

                    
                </div>
            </div>                        

                          

        </div>        
                                 
    </section>
</template>


<style scoped>
.has-background-dark {
    background-color: #363636d6 !important;
}

</style>

<script>
    import { mapState, mapGetters } from 'vuex'
    import modalInserirEntregaFutura from '@/components/venda/modalInserirEntregaFutura'
    import modalObservacaoGuia  from '@/components/atendimento/modalObservacaoGuia'
    import modalAlteracaoPrazoConferente  from '@/components/venda/modalAlteracaoPrazoConferente'    
    import modalAlteracaoPrazoFlowall  from '@/components/venda/modalAlteracaoPrazoFlowall.vue'    

    export default {
        data() {
            return {        
                agendaHoje: [],                        
                isLoading: false,  
                listaVendasEntregaFutura: [],                 
                listaVendasRevisao: [],                                
                listaVendasValidacao: [],
                totalVendasValidacao: 0,                
                listaVendasFinanceiro: [],                              
                listaVendasEletro: [],  
                listaVendasAssinatura: [], 
                listaVendasMedidor: [],
                listaVendasConferenciaAutorizada: [],
                totalVendasConferenciaAutorizada: 0,
                listaVendasConferenciaConcluida: [],
                totalVendasConferenciaConcluida: 0, 
                listaVendasEnvioProducao: [],
                listaVendasFinalizarEfinance: [],
                listaVendasPlantas: [],                    
                habilitaSelecionarUnificarConferenciaEmEspera:false,
                habilitaSelecionarUnificarConferenciaEmExecucao: false,
                habilitaSelecionarUnificarEnvioProducao:false,
                habilitaSelecionarUnificarFinalizarEfinance:false,
                atendimentoSelecionadosUnificar: [],   
                
                
                orderRevisarEfetivacao: 0,
                iconeOrderRevisarEfetivacao:'',
                orderValidarEfetivacao: 0,
                iconeOrderValidarEfetivacao:'',                                
                orderFinanceiro: 0,
                iconeOrderFinanceiro:'',
                orderEletros: 0,
                iconeOrderEletros:'',   
                orderAssinatura: 0,
                iconeOrderAssinatura:'',
                orderMedidor: 0,
                iconeOrderMedidor:'',                                
                orderConferenciaEmEspera: 0,
                iconeOrderConferenciaEmEspera:'',
                orderConferenciaEmExecucao: 0,
                iconeOrderConferenciaEmExecucao:'',                                   
                orderEnvioProducao: 0,
                iconeOrderEnvioProducao:'',                                   
                orderFinalizarEFinance: 0,
                iconeOrderFinalizarEFinance:'',                                   
                orderPlantas: 0,
                iconeOrderPlantas:'',  
                
                
                collapses: [false, false, false, false, false],
                queryCliente: this.$route.params.queryCliente ?? null,  
            }
        },
        components: {  
                                  
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'usuario'                
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData()                                                
            },
        },         
        methods: {

            verificaPesquisarCliente(){
                
                if(this.queryCliente){
                    this.isLoading = true;
                    setTimeout(() => { this.pesquisarCliente(this.queryCliente)},5000);                                    
                }

            },            
            pesquisarCliente(queryCliente){
                this.isLoading = false;                             
                this.collapsesOpenAll();                    
                setTimeout(() => { this.barraRolagemCliente(this.queryCliente)},1000);                     
             
            },
            barraRolagemCliente(queryCliente){
                const searchElements = document.querySelectorAll(".highlight"); // Seleciona os elementos com a classe 'searchable'


                let found = false;

                searchElements.forEach(element => {
                    const regex = new RegExp(`(${queryCliente})`, "gi");
                    element.innerHTML = element.innerHTML.replace(regex, `<mark>$1</mark>`);

                    // Rola até o primeiro destaque encontrado
                    if (!found) {
                    const firstHighlight = element.querySelector("mark");
                    if (firstHighlight) {
                        firstHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
                        found = true; // Garante que rola apenas para o primeiro match
                    }
                    }
                });   
            },
            collapsesOpenAll() {
                this.collapses = this.collapses.map(() => true);
                this.$forceUpdate();
            },
            collapsesCloseAll() {
                this.collapses = this.collapses.map(() => false);
                this.$forceUpdate();
            },            
            retornaEstiloLinha(item){

                if(item.atraso){
                    return 'bg-atraso'
                }else if(item.inside){
                    return 'has-background-dark has-text-white';
                }else{
                    return '';
                }
            },   
            confirmarAlteracaoPrazoFlowall(item){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlteracaoPrazoFlowall,
                    props: {
                        venda: item                        
                    },
                    events: {
                        loadData: this.loadAsyncData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },                     
            confirmarAlteracaoPrazoConferente(item){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlteracaoPrazoConferente,
                    props: {
                        venda: item                        
                    },
                    events: {
                        loadData: this.loadAsyncData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },                      
            verificaOrdenarIcone(item){                
                if(item==0){
                    return '';
                }else if(item==1){
                    return 'sort-alphabetical-ascending';
                }else if(item==2){
                    return 'sort-alphabetical-descending';
                }else{
                    return '';
                }
            },    
            ordenar(etapa){
  
                if(etapa == 'RevisarEfetivacao'){
                    this.iconeOrderRevisarEfetivacao = this.verificaOrdenarIcone(this.orderRevisarEfetivacao);   
                    this.vendasRevisao();            
                } else if(etapa == 'ValidarEfetivacao'){
                    this.iconeOrderValidarEfetivacao = this.verificaOrdenarIcone(this.iconeOrderValidarEfetivacao);   
                    this.vendasValidar();            
                }else if(etapa == 'Financeiro'){                    
                    this.iconeOrderFinanceiro = this.verificaOrdenarIcone(this.iconeOrderFinanceiro);   
                    this.vendasFinanceiro();                 
                } else if(etapa == 'Eletros'){
                    this.iconeOrderEletros = this.verificaOrdenarIcone(this.orderEletros);   
                    this.vendasEletro();                 
                } else if(etapa == 'Assinatura'){
                    this.iconeOrderAssinatura = this.verificaOrdenarIcone(this.orderAssinatura);   
                    this.vendasAssinatura();            
                }else if(etapa == 'Medidor'){                    
                    this.iconeOrderMedidor = this.verificaOrdenarIcone(this.orderMedidor);   
                    this.vendasMedidor();                 
                } else if(etapa == 'ConferenciaEmEspera'){
                    this.iconeOrderConferenciaEmEspera = this.verificaOrdenarIcone(this.orderConferenciaEmEspera);   
                    this.vendasConferenciaAutorizada();                 
                } else if(etapa == 'ConferenciaEmExecucao'){
                    this.iconeOrderConferenciaEmExecucao = this.verificaOrdenarIcone(this.orderConferenciaEmExecucao);   
                    this.vendasConferenciaConcluida();                 
                } else if(etapa == 'EnvioProducao'){
                    this.iconeOrderEnvioProducao = this.verificaOrdenarIcone(this.orderEnvioProducao);   
                    this.vendasEnvioProducao();                 
                } else if(etapa == 'FinalizarEFinance'){
                    this.iconeOrderFinalizarEFinance = this.verificaOrdenarIcone(this.orderFinalizarEFinance);   
                    this.vendasFinalizarEfinance();                 
                } else if(etapa == 'Plantas'){
                    this.iconeOrderPlantas = this.verificaOrdenarIcone(this.orderPlantas);   
                    this.vendasPlantas();                 
                } 
            },                    

            changedUnificar(item){

                if(this.atendimentoSelecionadosUnificar.length>0 && item.unificarAtendimento ){

                    if(this.atendimentoSelecionadosUnificar.filter(x=>x.clienteCodigoExterno == item.clienteCodigoExterno).length==0 ){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('POSVENDAS.ATENDIMENTOSMESMOCONTRATO'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });  
                        setTimeout(() => { item.unificarAtendimento = false;},500);                   
                        return;                           
                    }

                }

                if(item.unificarAtendimento){
                    this.atendimentoSelecionadosUnificar.push(item);
                }else{
                    this.atendimentoSelecionadosUnificar = this.atendimentoSelecionadosUnificar.filter(x=>x.guiaId != item.guiaId);
                }
                    
            },            
            habilitarSelecionarUnificar(etapa,habilitar){
                if(etapa=="ConferenciaEmEspera"){
                    this.habilitaSelecionarUnificarConferenciaEmEspera = habilitar;
                }    

                if(etapa=="ConferenciaEmExecucao"){
                    this.habilitaSelecionarUnificarConferenciaEmExecucao = habilitar;
                }    
                if(etapa=="EnvioProducao"){
                    this.habilitaSelecionarUnificarEnvioProducao = habilitar;
                }           
                
                if(etapa=="FinalizarEfinance"){
                    this.habilitaSelecionarUnificarFinalizarEfinance = habilitar;
                }                           
                                                         
            },
            selecionarAtendimentosUnificar(etapa){                
                this.habilitarSelecionarUnificar(etapa,true);
                this.atendimentoSelecionadosUnificar = []
            },  
            cancelarSelecionarUnificar(etapa){                
                this.habilitarSelecionarUnificar(etapa,false);
                this.atendimentoSelecionadosUnificar = [];                
                this.listaVendasConferenciaAutorizada.forEach((item) => item.unificarAtendimento =false );
                this.listaVendasConferenciaConcluida.forEach((item) => item.unificarAtendimento =false );
            },          
            confirmarUnificar(etapa,possuiConferente){

                if(this.atendimentoSelecionadosUnificar.length<=1){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('POSVENDAS.MENSAGEMUNIFICARATENDIMENTOS'),
                        type: 'is-warning',
                        hasIcon: true
                    });  
                    
                    return false;
                }

                this.$buefy.dialog.confirm({
                title: 'Confirmar',
                message: this.$t('POSVENDAS.MENSAGEMCONFIRMARUNIFICARATENDIMENTOS'),
                type: 'is-warning',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {

                    try {          
                        
                            const params = [                                        
                            `possuiConferente=${possuiConferente}`
                            ];  

                            this.$http.post(`/api/venda/ConfirmarUnificarAtendimentos?${params.join('&')}`,this.atendimentoSelecionadosUnificar)
                            .then(res => res.data)
                            .then(data => {
                                return true;                                 
                            }).finally(() => {                                
                                this.vendasConferenciaAutorizada();
                                this.vendasConferenciaConcluida();  
                                this.vendasEnvioProducao();
                                this.vendasFinalizarEfinance();
                                this.habilitarSelecionarUnificar(etapa,false);                          
                            });                            

                            
                        } catch (e) {                                                  
                            console.error(e);
                        }

                    }
                });

                
            },  

            confirmarInclusaoObservacao(guiaId) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalObservacaoGuia,
                    props: {
                        guia: {id: guiaId}                                              
                    },                   
                    hasModalCard: true,
                    trapFocus: true
                })
            },          
            confirmarInserirEntregaFuturaVenda(guiaId){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalInserirEntregaFutura,
                    props: {
                        id: guiaId                        
                    },
                    events: {
                        loadData: this.loadAsyncData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },           
            tipoAgendamentoStyle(evento) {
                return 'color: #' + evento.tipoAgendamentoCor + '!important'
            },                     
            carregarAgenda() {      

                const tipo = 2;
                const params = [                    
                    `unidadeId=${this.unidadeId}`,                                                      
                    `tipo=${tipo}`,
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/AgendaHojeVenda?${params.join('&')}`)                
                    .then(res => {
                        this.agendaHoje = res.data
                    });
            },         
            mostraMensagemAguardandoAlteracao(){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('VENDAS.MENSAGEMAGUARDANDOALTERACAO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
            },
            async loadAsyncData() {    
        
                await this.carregarAgenda();  
                await this.vendasEntregaFutura(); 
                await this.vendasRevisao(); 
                await this.vendasValidar(); 
                await this.vendasFinanceiro();                
                await this.vendasEletro();

                await this.vendasAssinatura();
                await this.vendasMedidor();
                await this.vendasConferenciaAutorizada();
                await this.vendasConferenciaConcluida();
                await this.vendasEnvioProducao();
                await this.vendasFinalizarEfinance();
                await this.vendasPlantas();


                if(this.isVendedor()){
                    this.vendasValidarTotal();                
                    this.vendasConferenciaAutorizadaTotal();
                }
                    

            },


            vendasEntregaFutura(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasEntregaFutura?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasEntregaFutura = data;
                    })
                    .catch((error) => {
                        this.listaVendasEntregaFutura = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },    
            
            vendasRevisao(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderRevisarEfetivacao}`                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/vendasRevisao?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasRevisao = data;
                    })
                    .catch((error) => {
                        this.listaVendasRevisao = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },    
            
            vendasValidar(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderValidarEfetivacao}`                                                                                                            
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasValidar?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasValidacao = data;
                    })
                    .catch((error) => {
                        this.listaVendasValidacao = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },  
            
            vendasValidarTotal(){
                
                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];

                this.$http.get(`/api/dashboard/VendasValidar?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.totalVendasValidacao = data.length;
                    })
                    .catch((error) => {                        
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })     
                
            },      
            
            vendasFinanceiro(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderFinanceiro}`                                                                                                                                                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasFinanceiro?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasFinanceiro = data;
                    })
                    .catch((error) => {
                        this.listaVendasFinanceiro = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },  

            vendasEletro(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderEletros}`                                                                                                                                                                                                                                                                
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasEletro?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasEletro = data;
                    })
                    .catch((error) => {
                        this.listaVendasEletro = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },  

            vendasAssinatura(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderAssinatura}`                                                                                                                                                                                                                                                                                                                                          
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasAssinatura?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasAssinatura = data;
                    })
                    .catch((error) => {
                        this.listaVendasAssinatura = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },  


            vendasMedidor(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderMedidor}`                                                                                                                                                                                                                                                                                                                                                                                                                    
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasMedidor?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasMedidor = data;
                    })
                    .catch((error) => {
                        this.listaVendasMedidor = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },  
            
            vendasConferenciaAutorizada(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderConferenciaEmEspera}`                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasConferenciaAutorizada?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasConferenciaAutorizada = data;
                    })
                    .catch((error) => {
                        this.listaVendasConferenciaAutorizada = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },  

            vendasConferenciaAutorizadaTotal(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];

                this.$http.get(`/api/dashboard/VendasConferenciaAutorizada?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.totalVendasConferenciaAutorizada = data.length;
                    })
                    .catch((error) => {                        
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },                  
            
            vendasConferenciaConcluida(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderConferenciaEmExecucao}`                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`); 
                
                if(this.usuario.nivel == 'Conferente' )
                    params.push(`projetistaId=${this.usuario.funcionarioId}`);                                
                

                this.$http.get(`/api/dashboard/VendasConferenciaConcluida?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasConferenciaConcluida = data;
                    })
                    .catch((error) => {
                        this.listaVendasConferenciaConcluida = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            }, 
            
            vendasConferenciaConcluidaTotal(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];

                this.$http.get(`/api/dashboard/VendasConferenciaConcluida?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.totalVendasConferenciaConcluida = data.length;
                    })
                    .catch((error) => {                        
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },              
            
            vendasEnvioProducao(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderEnvioProducao}`                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasEnvioProducao?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasEnvioProducao = data;
                    })
                    .catch((error) => {
                        this.listaVendasAssinatura = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },  
            
            vendasFinalizarEfinance(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderFinalizarEFinance}`                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasFinalizarEfinance?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasFinalizarEfinance = data;
                    })
                    .catch((error) => {
                        this.listaVendasFinalizarEfinance = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },         
            
            vendasPlantas(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderPlantas}`                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VendasPlantas?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaVendasPlantas = data;
                    })
                    .catch((error) => {
                        this.listaVendasPlantas = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },                 
                                                       
            isVendedor(){                
                return this.usuario.nivel == 'Vendedor' || this.usuario.nivel.includes('Vendedor');
            },        
            isConferente(){                
                return this.usuario.nivel == 'Conferente';
            },
            loadTotalNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',data)
                    })
                    .catch((error) => {                        
                        throw error
                    });

                this.$http.get('/api/notificacao/RetornaTotalMensagensFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                            this.$store.commit('componentes/SET_TOTAL_MENSAGENS',data)
                        
                    })
                    .catch((error) => {                        
                        throw error
                    });                      
                
            },                          
                            
        },
        mounted() {                        
            this.loadAsyncData();   
            this.loadTotalNotificacoesFuncionario(); 
            this.verificaPesquisarCliente();
            
            
        }
    }
</script>