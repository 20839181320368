<template> 
  <section class="hero is-medium">
    <b-loading :active.sync="isLoading"></b-loading>
    <div v-if="this.listaAtrasados.length>0">      
      <b-notification type="is-warning">
                <p><b>Atenção! Você tem alguns clientes em atraso. Para continuar, é necessário justificar os atrasos listados abaixo.</b></p>
      </b-notification>  
      
      <div  v-for="(item, index) in listaAtrasados" v-bind:key="index" class="columns is-multiline cardAtendimento" style="margin-bottom: 5px !important;">
            <div class="bg-atraso column is-3">
                <b-tag  type ="is-light" size="is-large"> <span class="icon is-small"></span> &nbsp;&nbsp; {{item.etapa}} </b-tag>                                                    
            </div> 
            <div class="bg-atraso column is-3">
                <small>{{$t('SISTEMA.CLIENTE')}}</small>
                <br />
                <span>                      
                  <b>{{ item.clienteCodigoExterno }} - {{ item.clienteNome }}</b>                  
                </span>
            </div>
            <div class="bg-atraso column is-3">
                <span>
                    <small>{{$t('SISTEMA.FUNCIONARIO')}}</small>
                    <br />
                    <span v-if="item.funcionarioNome"><b>{{ item.funcionarioNome.toUpperCase() }}</b></span>
                    
                </span>                
            </div>            
            <div class="bg-atraso column " >                
                <span class="is-size-7"><b>{{$t('ATENDENTE.ENTRADA')}}</b></span>
                <br />                
                <span class="is-size-7">{{ $moment(item.dataHoraEntrada).format("DD/MM/YYYY  HH:mm") }} </span>


            </div>     
            <div class="bg-atraso column is-2"> 
            
              <b-button class="button is-primary"   size="is-medium" icon-left="pencil"                                    
                @click="abrirGuia(item.guiaId,unidadeId,item.statusGuia)" title="Abrir Projeto">                 
              </b-button>  

                                                                      
             </div>                                                                    
        </div>
      
    </div>
    <div v-else class="hero-body">
      <div class="container has-text-centered">
        <img alt="FLORENSE" width="300" src="../assets/logo.png">
        <h2 class="subtitle">
          {{$t('SOBRE.APRESENTACAO')}}
        </h2>    
        <h2 class="subtitle">
          <span class="tag is-black">{{$t('SOBRE.VERSAO')}} {{config.releaseNumber}}</span>
          <span class="tag is-info">{{config.releaseDate}}</span>
        </h2>
      </div>
    </div>
  </section>
</template>

<style scoped>
.cardAtendimento {
    background-color: #fff;
    cursor: pointer;
}

.cardAtendimento:hover {
    background-color: hsl(0, 0%, 96%)
}
</style>

<script>
    import { mapState } from 'vuex'
    import modalNotificacao from '@/components/modalNotificacao.vue'
    import jwt from 'jsonwebtoken'

    export default {

      data() {
            return {
              bloquearUsuarioMenu:false,
              listaAtrasados:[],
              isLoading:false,
            }
        },      
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'usuario',
                'componentes'
            ]),
        },

        methods:{

            abrirGuia(guiaId,unidadeId,status){

                if(status==0 || status==3){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId, voltarTela: 'Sobre'}})
                }else if (status == 1){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,fluxoVenda:true }})
                }else if(status == 5){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,fluxoVenda:true }})
                }else{
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId}})
                }
                                
          },          
          montaParametros(){
                const params = [                                        
                    `unidadeId=${this.unidadeId}`,  
                    `funcionarioId=${this.usuario.funcionarioId}`                                                               
                ];

                return params;
          },           
          verificaTravaMenu(){     

            this.$store.commit('componentes/SET_USUARIOBLOQUEADO', false);

            if(this.usuario.bloquearacessoatrasos){
                        
              const params = this.montaParametros();
                    
              this.isLoading = true;                    

              this.$http.get(`/api/atrasados/RetornarAtrasados?${params.join('&')}`)
                  .then(({ data }) => {
                      this.listaAtrasados = data;  
                      this.isLoading = false                                                        
                  })
                  .catch((error) => {
                      this.listaAtrasados = []                      
                      this.isLoading = false
                      throw error
                  }).finally(() => {                        
                      if(this.listaAtrasados !=null && this.listaAtrasados.length>0  && this.listaAtrasados.length > this.usuario.quantidadeatrasos){
                        this.$store.commit('componentes/SET_USUARIOBLOQUEADO', true);
                        this.componentes.sidebarIsOpened = false;   
                        document.getElementById("icon-sidebar").style.display = "none";   
                      }else{
                        this.listaAtrasados = [] ;
                        this.$store.commit('componentes/SET_USUARIOBLOQUEADO', false);
                        this.componentes.sidebarIsOpened = true;   
                        document.getElementById("icon-sidebar").style.display = "block";   
                      }
                  }); 
              }

                      
          },
 
          abrirModalNotificacao(podeCancelar) {

                const existingModal = document.querySelector('.modal'); 
                if(!existingModal){
                  this.$buefy.modal.open({
                        parent: this,
                        component: modalNotificacao,                                                      
                        hasModalCard: true,
                        trapFocus: true,                    
                        canCancel: false
                    })              

                }            
            
          },

          loadTotalNotificacoesFuncionario() {


                  this.$http.get('/api/notificacao/RetornaTotalMensagensFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                      .then(res => res.data)
                      .then(data => {   
                      
                        if(data>0){
                          this.abrirModalNotificacao();
                        }else{
              
                            this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                                    .then(res => res.data)
                                    .then(data => {     
                                                                    
                                    if(data>0)
                                      this.abrirModalNotificacao();
                                })
                                .catch((error) => {                        
                                    throw error
                                });                            
                        }
                          
                                                                          
                  })
                  .catch((error) => {                        
                      throw error
                  });
                  
                  
                  
              
          }, 
          checkAbrirGuia(){       

             // caso em que usuario ja esta com sessao logada, ou seja nao passa pela tela de login
             if (this.$route.query.token) {                    
                  this.Token = this.$route.query.token;
                  const json = jwt.decode(this.Token);
                  this.$store.commit('CLEAR_ABRIRGUIA');
                  this.$store.commit('SET_UNIDADE', json.UnidadeId);
                  this.$router.push({name: 'guia', params: {id: json.GuiaId}})  
              }
                
            

              // caso em que usuario nao estava logado
              if(this.usuario.abrirGuiaId!=null && this.usuario.abrirGuiaId!=""
               && this.usuario.abrirUnidadeId!=null  && this.usuario.abrirUnidadeId!=""){                
                const guiaId = this.usuario.abrirGuiaId;
                const unidadeId = this.usuario.abrirUnidadeId;

                this.$store.commit('CLEAR_ABRIRGUIA');
                this.$store.commit('SET_UNIDADE', unidadeId);
                this.$router.push({name: 'guia', params: {id: guiaId}})                
              }

          }  
                                 
        },
        mounted() {                        
            this.loadTotalNotificacoesFuncionario();
            setTimeout(() => {this.checkAbrirGuia()},500);         
            this.verificaTravaMenu();            

        },

        
             
    }
</script>
