<template>
  <section>
    <titlebar :title-stack="titleStack"></titlebar>
    <div v-if="isLoading">
        <b-loading active></b-loading>
    </div>
    <div v-else>
      <div class="columns is-multiline">
        <div 
          v-for="(card, index) in cards" 
          :key="index" 
          class="column is-2"
        >
          <p class="title is-size-5 has-text-centered is-4">{{ card.title }}</p>
          <div class="card custom-card is-flex is-flex-direction-column has-text-centered">
            <div class="card-content px-0">
              <div class="content has-text-white">
                <span v-if="card.quantidade">{{ card.quantidade }}</span>
                <template v-if="card.valores">
                  <div v-for="(valor, i) in card.valores" :key="i">
                    {{ valor }}
                  </div>
                </template>
                <template v-if="card.instalacoes">
                  <div v-for="(intalacao, i) in card.instalacoes" :key="i">
                    {{ intalacao }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .custom-card {
    background-color: #000 !important;
    border-radius: 15px !important;
    margin: 8px;
    min-height: 7.5rem;
  }

  .content {
    font-size: 1rem;
    line-height: 1.5;
  }
</style>

<script>
  import titlebar from '@/components/titlebar.vue'

  export default {
    data() {
      return {
        isLoading: false,
        cards: [
          { 
            title: 'Vendas no Ano',
            quantidade: '20',
            valores: ['R$ 2.000.000,00', 'R$ 100.000,00 T']
          },
          { 
            title: 'Temp. Quente',
            quantidade: '10',
            valores: ['R$ 5.000.000,00']
          },
          { 
            title: 'Conferencia', 
            quantidade: '10',
            valores: ['R$ 1.200.000,00']
          },
          { 
            title: 'Instalações futuras',
            quantidade: '12',
            valores: ['R$ 1.000.000,00']
          },
          { 
            title: 'Vendas no mês',
            quantidade: '2',
            valores: ['R$ 500.000,00']
          },
          { 
            title: 'Eletros',
            quantidade: '17',
            valores: ['R$ 8.000.000,00']
          },
          { 
            title: 'Conferidos no Ano',
            quantidade: '12',
            valores: ['R$ 2.000.000,00']
          },
          { 
            title: 'Instalações Incompl.',
            instalacoes: ['Certificado 10', 'Ástec 12', 'Ambos 5'] 
          },
          { 
            title: 'Meta Mensal',
            quantidade: '80%',
            valores: ['R$ 2.500.000,00']
          },
          { 
            title: 'Assinatura',
            quantidade: '15',
            valores: ['R$ 10.000.000,00']
          },
          { 
            title: 'Conferidos no mês',
            quantidade: '5',
            valores: ['R$ 2.300.000,00']
          },
          { 
            title: 'Especificador',
            quantidade: '90%',
            valores: ['R$ 1.800.000,00']
          },
          { 
            title: 'Medição',
            quantidade: '19',
            valores: ['R$ 4.300.000,00']
          },
          { 
            title: 'Instalações Ano',
            quantidade: '20',
            valores: ['R$ 3.000.000,00']
          },
          { 
            title: 'Em negociação',
            quantidade: '61',
            valores: ['R$ 12.000.000,00']
          },
          { 
            title: 'Conf. Espera',
            quantidade: '12',
            valores: ['R$ 1.000.000,00']
          },
          { 
            title: 'Instalações mês',
            quantidade: '15',
            valores: ['R$ 800.000,00']
          }
        ]
      }
    },
    components: {
        titlebar
    },
    computed:{
      titleStack() {
          return [
              'Relatório',                                        
              'Dashboard',
              'Vendedores'
          ]
      },
    }
  }
</script>