<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALTROCARVENDEDORGUIA.TROCARVENDEDORGUIA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALTROCARVENDEDORGUIA.AVISOTROCAVENDEDOR')}}</p>
            </b-notification>
            <div class="panel">

                <p class="panel-block">
                    <searchIdName class="column is-9" :id.sync="funcionarioComercialId" :trazDesativado="false" 
                    :label="$t('GUIAATENDIMENTO.VENDEDOR')" table="FuncionarioComercial"
                    :camposAdicionais="'&unidadeId='+unidadeId" :leitura="true">
                    </searchIdName>                                                                                  
                </p>     

                <p class="panel-block">
                    <searchIdName class="column is-9" :id.sync="novoFuncionarioComercialId" :trazDesativado="false" 
                    :label="$t('MODALTROCARVENDEDORGUIA.NOVOVENDEDOR')" table="FuncionarioComercial"
                    :camposAdicionais="'&unidadeId='+unidadeId" >
                    </searchIdName>                                                                                  
                </p>                     

                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                     :loading="salvandoDados"
                      @click="confirmarTrocaVendedor">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    import searchIdName from '@/components/searchIdName.vue'    
    
    export default {
        props: {
            guiaId: Number,
            guiaFuncionarioComercialId: Number                                                         
        },
        data() {
            return {                                                          
                observacoes: null,
                salvandoDados :false,
                funcionarioComercialId:null,
                novoFuncionarioComercialId:null,
            }
        },
        components: {                     
            searchIdName            
        },         
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {            
            setTimeout(() => {this.funcionarioComercialId = this.guiaFuncionarioComercialId },500);
        },
        methods: {

            async confirmarTrocaVendedor() {

                if(this.funcionarioComercialId > 0 && 
                 this.novoFuncionarioComercialId > 0 ){

                    if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }                      

                    this.salvandoDados=true;
                    const params = [];                    
                    params.push(`guiaId=${this.guiaId}`); 
                    params.push(`funcionarioComercialId=${this.funcionarioComercialId}`);                                                            
                    params.push(`novoFuncionarioComercialId=${this.novoFuncionarioComercialId}`);                                                            
                    params.push(`observacoes=${this.observacoes}`); 
                                                                    
                    this.$http.get(`/api/atendimento/trocarVendedor?${params.join('&')}`)
                            .then(res => res.data)
                            .then(data => {
                                this.salvandoDados=false; 
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t("SISTEMA.SALVOSUCESSO"),
                                    type: 'is-success'
                                }); 
                            }).finally(() => {                                      
                                this.$emit('loadData');                                                                  
                                this.$emit('close');                                                                  
                            });  

                }

                return true

            },
            

        }
    }
</script>