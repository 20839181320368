import { render, staticRenderFns } from "./administrativo.vue?vue&type=template&id=7ae1a60e&scoped=true&"
import script from "./administrativo.vue?vue&type=script&lang=js&"
export * from "./administrativo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ae1a60e",
  null
  
)

export default component.exports