<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('NIVELDEACESSOCADASTRO.SALVANDONIVEL')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SISTEMA.NOME')"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="50"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div  class="column is-one-third">
         
                                </div>                                
                            </div>

                            <cadastro-items v-if="model.administrador" :label="$t('NIVELDEACESSOCADASTRO.UNIDADESDECADASTRO')" :items.sync="model.unidadesDeAcesso" tabela="unidade" style="width:99%" class="mt-4"/>                            
                  
                        </article>
                    </div>
                    <div class="tile is-parent">

                        <article class="tile is-child">
                            <article class="panel">
                                <div>
                                    <div class="panel-block">
                                        <b-switch v-model="model.administrador">
                                            {{$t('NIVELDEACESSOCADASTRO.ADM')}}
                                        </b-switch>  
                                    </div>
                                    <div class="panel-block" style="display: block;">
                                        <span>
                                            <b-switch v-model="model.bloquearAcessoAtrasos">
                                                {{$t('NIVELDEACESSOCADASTRO.BLOQUEARACESSO')}}
                                            </b-switch>  
                                        </span>
                                        
                                        <span v-if="model.bloquearAcessoAtrasos">                                            
                                            <b-input type="number" v-model="model.quantidadeAtrasos" ></b-input> 
                                        </span>                                                                            
                                    </div>
                                                                             
                                </div>
                            </article>  
                            <historico-alteracoes :tabela="'NivelDeAcesso'" :chave="model.id"></historico-alteracoes>     
                        </article>                        
                                          

                        
                                                                      
                                              
                    </div>
                </div>
                <article class="tile is-child box" v-if="model.regrasExistentes != null && model.regrasExistentes.length > 0">
                    <b>{{$t('NIVELDEACESSOCADASTRO.REGRAS')}}</b>
                    <template v-for="(grupo, i) in grupos">
                        <collapse-groups :index="i" :titulo="grupo" v-bind:key="i">
                            <tbody>
                                <tr v-for="(item, index) in model.regrasExistentes.filter(x => x.grupo == grupo)" v-bind:key="index">
                                    <td>
                                        <input @change="alterouNivel(item.nome)" type="checkbox" v-if="model.regras.filter(x => x.nome == item.nome).length > 0" checked>
                                        <input @change="alterouNivel(item.nome)" type="checkbox" v-else>
                                    </td>                                    
                                    <td>{{item.descricao}}</td>
                                </tr>
                            </tbody>
                        </collapse-groups>
                        <br v-bind:key="i">
                    </template>
                </article>
                <br>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import collapseGroups from '@/components/manutencao/collapseGroups.vue'
    import Vue from 'vue'
    import cadastroItems from '@/components/manutencao/cadastroItems.vue'
import { min } from 'vee-validate/dist/rules'

    export default {
        data() {
            return {            
                model: {
                    id: null,
                    nome: null,                    
                    administrador: null,                    
                    regras: null,
                    regrasExistentes: null, 
                    unidadesDeAcesso: [],
                    bloquearAcessoAtrasos:null,
                    quantidadeAtrasos:0,                   
                },
                salvandoDados: false,                
                grupos:['Manutenção', 'Atendimento', 'Vendas', 'Pós Venda','Relatorio'],
                isLoading:false,
            }
        },
        components: {
            titlebar,
            historicoAlteracoes,
            collapseGroups,
            cadastroItems
        },
        computed: {
            ...mapState([
                'unidadeId'                            
            ]),            
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('NIVELDEACESSOCADASTRO.NIVEL'),
                    this.$route.params.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([            
                'usuario',                                
            ]),              
            
        },
        methods:{
            onSubmit() {
                this.salvandoDados = true;    


                if(!this.model.administrador)
                    this.model.unidadesDeAcesso = [];                
                
                this.model.UnidadeDeCadastro = { id : this.unidadeId};

                if(!this.model.bloquearAcessoAtrasos)
                    this.model.quantidadeAtrasos = 0;

                this.$http.post(`/api/manutencao/nivel`, this.model )
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });       
            },

            alterouNivel(regra){
                if(this.model.regras == null){
                    this.model.regras = [];
                }

                if(this.model.regras.filter(x => x.nome == regra).length == 0){
                    this.model.regras.push({
                        id: this.model.regrasExistentes.filter(x => x.nome == regra)[0].id,
                        nome: this.model.regrasExistentes.filter(x => x.nome == regra)[0].nome
                    });
                }
                else 
                {
                    let index = -1;
                    for (const i in this.model.regras) {
                        if(this.model.regras[i].nome == regra) {
                            index = i;
                            break;
                        }
                    }

                    if(index > -1)
                    {
                        Vue.delete(this.model.regras, index);
                    }
                }
            }
        },
        created() {

            this.isLoading= true;

            let url = '/api/manutencao/niveldeacesso?id=' + this.$route.params.id
            if(!this.$route.params.id){
                url = '/api/manutencao/niveldeacesso'
            }
            this.$http.get(url)
                .then(res => {
                    this.model = res.data;

                    if(this.model.regras == null){
                        this.model.regras = [];
                    }                  
                    
                }).finally(() => {
                        this.isLoading= false;
                });
        }
    }
</script>