<template>
    <div class="modal-card" style="width: auto; overflow-y: auto;">
        <div v-if="listaMensagens && listaMensagens.length>0">
            <header class="modal-card-head">
                <p  class="modal-card-title">{{$tc('MODALNOTIFICACAO.TITULOMENSAGEM', listaMensagens.length) }}                
                </p>                            
            </header>
            <section class="modal-card-body list is-paddingless is-marginless">
                <b-notification v-if="listaMensagens && listaMensagens.length>0" type="is-warning">
                    <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>
                    <p>{{$t('MODALNOTIFICACAO.MENSAGEMLEMBRETEDEVEMSERLIDAS')}}</p>
                </b-notification>
                <div class="list-item p-2  " v-for="item in listaMensagens" :key="item.id">
                    <div class=" notification is-primary cp" style="background-color: rgb(186 209 0 / 19%);">
                    
                        <div class="tags has-addons is-marginless">                    
                            <span class="tag is-primary"> <b>{{ item.unidadeNome}}</b> </span>                    
                                                
                            <span class="tag">{{ item.datahora | moment('DD/MM/YYYY HH:mm:ss') }}</span>
                            <a class="tag is-info " @click="adicionarObservacaoResposta(item.guiaObservacaoId);" :title="$t('MODALNOTIFICACAO.RESPONDERMENSAGEM')"> <b-icon  icon="comment" size="is-small"></b-icon></a>
                            <a class="tag is-success " @click="apagarNotificacao(item.id);" :title="$t('MODALNOTIFICACAO.MARCARLIDA')"> <b-icon  icon="check-all" size="is-small"></b-icon></a>
                        </div>
                        <p class="is-paddingless has-text-black mb-2	">
                            <b> Remetente:</b>  {{item.funcionarioRemetenteNome  }} <br>
                            <b> Notificados:</b>  {{item.notificados  }} <br>
                            <b> Projeto: </b>  {{ item.clienteNome  }}
                        </p>                                            
                        <p class="is-paddingless has-text-black mb-2	"><b> <b-icon  icon="email-outline" ></b-icon> {{ item.descricao }}</b>
                            <b-button v-if="item.guiaId && item.guiaId >0 && listaMensagens && listaMensagens.length==0" class="is-primary is-small is-pulled-right" icon-left="pencil"                                    
                                    @click="abrirGuiaMensagem(item.guiaId,item.unidadeId,item.statusGuia)" title="Abrir Projeto"></b-button>   
                        </p>
                        <table  v-if="item.respostas!=null && item.respostas.length>0" class="table is-fullwidth">
                                <tr v-for="(resp, index) in item.respostas" :key="index">
                                    <td class="is-size-7 "><b>>>></b> {{ resp.observacao }}</td>                                                                        
                                    <td> <span class="is-size-7 "> <b>{{ resp.usuarioNome }}</b>  <br>{{ resp.dataHora | moment('DD/MM/YYYY HH:mm') }}</span></td>                                                                            
                                </tr>                                                                   
                        </table>        
                    </div>
                <hr class="m-0">
            </div> 
            </section>
        </div>
        <header class="modal-card-head">
            <p class="modal-card-title">{{$tc('MODALNOTIFICACAO.TITULO', this.listaNotificacoes.length) }}                
            </p>
            <b-button v-if="hasNotifications" icon-left="delete" class="is-danger is-small" @click="apagarTodasAsNotificacoes();$parent.close();">{{$t('MODALNOTIFICACAO.APAGARTODAS')}}</b-button>
                        
        </header>
        <section class="modal-card-body list is-paddingless is-marginless">
            
            <div class="list-item p-2 "
                 v-for="item in listaNotificacoes" :key="item.id">
                <div class="tags has-addons is-marginless">                    
                    <span class="tag is-primary"> <b>{{ item.unidadeNome}}</b> </span>
                    <span v-if="item.tipo==1" class="tag is-warning"> Alerta </span>
                    <span v-else class="tag is-info">Aviso</span>
                    <span class="tag">{{ item.datahora | moment('DD/MM/YYYY HH:mm:ss') }}</span>
                    <a class="tag is-danger is-delete" @click="apagarNotificacao(item.id);" :title="$t('MODALNOTIFICACAO.APAGAR')"></a>
                </div>
                <p class="is-paddingless has-text-black	mb-2">{{ item.descricao }}

                    <b-button v-if="item.guiaId && item.guiaId >0 && listaMensagens && listaMensagens.length==0" class="is-primary is-small is-pulled-right" icon-left="pencil"                                    
                                @click="abrirGuia(item.guiaId,item.unidadeId,item.statusGuia)" title="Abrir Projeto"></b-button>   

                </p>
                  
                <hr class="m-0">
            </div>
        </section>
        <footer  class="modal-card-foot">
            <button v-if="listaMensagens && listaMensagens.length==0" class="button" type="button" @click="$parent.close()">{{$t('MODALNOTIFICACAO.FECHAR')}}</button>        
            <button v-if="listaMensagens && listaMensagens.length==0" class="button is-info" type="button" @click="$parent.close(); $router.push({ name: 'notificacaoUsuarioLogado', params: { funcionarioId: usuario.funcionarioId} } );">{{$t('MODALNOTIFICACAO.VERHISTORICO')}}</button>         
        </footer>
    </div>
</template>



<script>
    import GuiaList from '@/views/atendimento/guia-list.vue';
    import { mapState } from 'vuex'
    import modalGuiaObservacaoResposta  from '@/components/atendimento/modalGuiaObservacaoResposta.vue'
    export default {
        data() {
            return {                                
                listaNotificacoes: [], 
                listaMensagens:[],                 

            }
        },
        mounted() {
            this.loadNotificacoesFuncionario();                                 
        },        
        computed: {
            hasNotifications() {
                return this.listaNotificacoes.length > 0;
            },
            ...mapState([                
                'usuario'
            ]),            
        },
        methods: {

            async adicionarObservacaoResposta(id){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalGuiaObservacaoResposta,
                    props: {
                        observacaoId: id                                              
                    },
                    events: {
                        loadData: this.loadNotificacoesFuncionario
                    },                    
                    hasModalCard: true,
                    trapFocus: true
                });             
            },        
            abrirGuiaMensagem(guiaId,unidadeId,status){
                if(status==0){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,guiaList:true}})
                }else if (status == 1){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,guiaList:true }})
                }else if(status == 5){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,guiaList:true }})
                }else{
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,guiaList:true}})
                }
                
                this.$parent.close();
            },
            abrirGuia(guiaId,unidadeId,status){

                if(status==0){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId}})
                }else if (status == 1){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,fluxoVenda:true }})
                }else if(status == 5){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,fluxoVenda:true }})
                }else{
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId}})
                }
                
                this.$parent.close();

            },
            procurarCliente(nomeCliente){
                
                this.$router.push({ name: 'guia-list', query: { procurar: nomeCliente}})

                this.$parent.close();

            },            
            loadNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/RetornaNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.listaNotificacoes = data;
                        
                    })
                    .catch((error) => {                        
                        throw error
                    }).finally(() => {
                        this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',this.listaNotificacoes.length)              
                    })  

                this.$http.get('/api/notificacao/RetornaMensagensFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.listaMensagens = data;
                        
                    })
                    .catch((error) => {                        
                        throw error
                    }).finally(() => {
                        this.$store.commit('componentes/SET_TOTAL_MENSAGENS',this.listaMensagens.length)
                    })                     
                
            },          
            async marcarTodasMensagensLidas() {

                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: 'Deseja marcar todas as mensagens como lidas?',
                    cancelText: 'Não',
                    confirmText: 'Sim', 
                    type:"is-info",                      
                    hasIcon: true,                                         
                    onConfirm: () => {   

                            this.listaMensagens.forEach((n) => {

                                this.$http.get('/api/notificacao/DesativarNotificacao?idNotificacao=' + n.id )
                                        .then(res => res.data)
                                        .then(data => {                           
                                        return true;                        
                                    })
                                    .catch((error) => {                        
                                        throw error
                                    });

                            });
                            setTimeout(() => {this.loadNotificacoesFuncionario()},1000);                         


                            return true;                                     
                            
                    },
                    onCancel: () => {   
                        this.$parent.close();
                    },
                })   

                
            },            
            async apagarTodasAsNotificacoes() {

                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: 'Deseja apagar todas notificações?',
                    cancelText: 'Não',
                    confirmText: 'Sim', 
                    type:"is-danger",                      
                    hasIcon: true,                                         
                    onConfirm: () => {   


                            this.listaNotificacoes.forEach((n) => {

                                this.$http.get('/api/notificacao/DesativarNotificacao?idNotificacao=' + n.id )
                                        .then(res => res.data)
                                        .then(data => {                           
                                        return true;                        
                                    })
                                    .catch((error) => {                        
                                        throw error
                                    });

                            });
                            setTimeout(() => {this.loadNotificacoesFuncionario()},1000);                                       
                            
                    },
                    onCancel: () => {   
                        this.$parent.close();
                    },
                })   

                
            },
            apagarNotificacao(id) {
                
                this.$http.get('/api/notificacao/DesativarNotificacao?idNotificacao=' + id )
                        .then(res => res.data)
                        .then(data => {                           
                        return true;                        
                    })
                    .catch((error) => {                        
                        throw error
                    }).finally(() => {
                        this.loadNotificacoesFuncionario();
                    })  

            }
        }
    }
</script>