import { render, staticRenderFns } from "./relatorio.vue?vue&type=template&id=2597c300&scoped=true&"
import script from "./relatorio.vue?vue&type=script&lang=js&"
export * from "./relatorio.vue?vue&type=script&lang=js&"
import style0 from "./relatorio.vue?vue&type=style&index=0&id=2597c300&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2597c300",
  null
  
)

export default component.exports