<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALLISTARPECAS.LISTARAMBIENTES')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALLISTARPECAS.AVISO')}}</p>
            </b-notification>            
            <div class="panel">
                 
                <p  class="panel-block">
                    <b-field :label="$t('MODALLISTARPECAS.AMBIENTES')">
                    </b-field>
                </p>                
                <div class="panel-block"> 
                    <table  v-if="ambientes !=null && ambientes.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Opção</th>
                                <th>Ambiente</th> 
                                <th>Tipo</th>
                                <th>Situação</th>                                                                                                       
                                <th>Nr. Elist</th>
                                <th>Valor</th>
                                                                                                     
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(ambiente, index) in ambientes" :key="index">
                                
                                <td>
                                        <b-checkbox :disabled="ambienteSelecionado!=null && !ambiente.selecionado"  @input="changed(ambiente)"
                                        size="is-small" type="is-info"  v-model="ambiente.selecionado"></b-checkbox >                                    
                                </td>                                
                                <td>{{ ambiente.opcao  }}</td>
                                <td>{{ ambiente.descricao }}</td>                                                    
                                <td>{{ ambiente.tipoDescricao }}</td>                            
                                <td>{{ ambiente.tipoSituacao }}</td>                                                                                                            
                                <td>{{ ambiente.elist }}</td>                                                                                                       
                                <td>R$ {{ ambiente.valor.toString().replace('.',',') }}</td> 
    
                            </tr>
                        </tbody>                        
                    </table>  
                </div>
                
                <p  v-if="pecas !=null && pecas.length>0"   class="panel-block">
                    <b-field :label="$t('MODALLISTARPECAS.PECAS')">
                    </b-field>
                </p>                
                <div  v-if="pecas !=null && pecas.length>0"   class="panel-block"> 
                    <table  class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Cód. Produto</th>
                                <th>Produto</th> 
                                <th>Quantidade</th>
                                <th>Px/Compl</th>
                                                                                                     
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(peca, index) in pecas" :key="index">
                                
                                <td>
                                        <b-checkbox :disabled="pecaSelecionada!=null && !peca.selecionado"  @input="changedPeca(peca)"
                                        size="is-small" type="is-info"  v-model="peca.selecionado"></b-checkbox >                                    
                                </td>                                
                                <td>{{peca.codigoProduto}}</td>                                                                                                       
                                <td>{{peca.descricao}}</td>                                                                                                       
                                <td>{{peca.quantidade}}</td>          
                                <td>{{peca.puxador}}</td>                                                                                                       

    
                            </tr>
                        </tbody>                        
                    </table>  
                </div>                
                            

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            guiaId: Number,   
            clienteCodigoExterno: Number,
            apenasEmAbertos: {
                type: Boolean,
                default: false
            }                 
        },
        data() {
            return {                                              
                salvandoDados :false,                
                ambientes:[],
                pecas:[],
                ambienteSelecionado:null,
                pecaSelecionada:null,                                 
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.loadAmbientes();
        },
        watch: {
            
            ambienteSelecionado: function() {                 
                if(this.ambienteSelecionado!=null){
                    this.loadPecas();
                }else{
                    this.pecas = [];
                }                                    
            },
        },       
        methods: {
                         
            changedPeca(peca){
                if(peca.selecionado){
                    this.pecaSelecionada = peca;                    
                }else{
                    this.pecaSelecionada = null;                    
                }                                
                
            },                           
            changed(ambiente){
                if(ambiente.selecionado){
                    this.ambienteSelecionado = ambiente;                    
                }else{
                    this.ambienteSelecionado = null;                    
                }                                
                
            },               
            loadAmbientes() {    
                this.salvandoDados = true;   
                
                const params = [];                                                                                                      
                params.push(`clienteId=${this.clienteCodigoExterno}`);  
                params.push(`guiaId=${this.guiaId}`);  
                params.push(`unidadeId=${this.unidadeId}`);                    

                this.$http.get(`/api/integracao/ListarAmbientesEList?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {     
                        this.ambientes = data;                       
                        this.isLoading=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                                                  
                    });               
                    
            },                        

            loadPecas() {    
                this.salvandoDados = true;   
                
                const params = [];                                                                                                      
                params.push(`clienteId=${this.clienteCodigoExterno}`);  
                params.push(`guiaId=${this.guiaId}`);  
                params.push(`unidadeId=${this.unidadeId}`);                 
                params.push(`opcao=${this.ambienteSelecionado.opcao}`); 

                this.$http.get(`/api/integracao/ListarPecasAmbiente?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {     
                        this.pecas = data;                       
                        this.isLoading=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                                                  
                    });               
                    
            },             
        
            async confirmar() {
                              
                if(this.pecaSelecionada==null){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALLISTARPECAS.MENSAGEMSELECIONEPECA'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;                              
                }else{                    
                    this.$emit('preencherTitulo', this.ambienteSelecionado.descricao + " - "  +  this.pecaSelecionada.codigoProduto + " - " + this.pecaSelecionada.descricao);
                    this.$emit('close');                                  
                }
                
      
                
            },
            

        }
    }
</script>