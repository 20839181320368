<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALINCLUIRDOCUMENTO.ANEXARDOCUMENTOS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
        

            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALINCLUIRDOCUMENTO.DATACADASTRO')">
                    </b-field>
                </p>  
                <div class="panel-block">                    
                    <b-datepicker 
                                  :placeholder="$t('SISTEMA.SELECIONEDATAHORA')"
                                  icon="calendar-today"
                                  v-model="model.dataCadastro">
                    </b-datepicker>                                                                      
                </div>                               

                <p class="panel-block">
                    <b-field :label="$t('FILTROS.TIPO')">
                    </b-field>
                </p>          
                
                <p class="panel-block">
                    <b-select expanded :placeholder="$t('FILTROS.TIPO')" v-model="model.tipo" style="width:100%;">                        
                            <option v-for="(item,index) in listaTipos" :key="index" :value="item.valor">
                                {{ item.descricao }}
                            </option>
                    </b-select>   
                </p>                       

                <p class="panel-block">
                    <b-field :label="$t('MODALINCLUIRDOCUMENTO.DOCUMENTO')">
                    </b-field>
                </p>       
                <p class="panel-block">

                    <b-field class="file is-primary" :class="{'has-name': !!documento}">
                        <b-upload
                        accept=".pdf,.jpg,.jpeg,.png,.PNG,.PDF,.JPG,.JPEG"
                            v-model="documento" class="file-label">
                            
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>                                                                                        
                                    <span class="file-label">Carregar Aquivo</span>
                                </span>                                            
                            <span class="file-name" v-if="documento">
                                {{ documento.name }}
                            </span>
                        </b-upload>
                    </b-field>                 

                </p>
                <br>
                <br>                       
            </div>

        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); $emit('removereletrosAnexoConcluidaVenda');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'    
    
    
    export default {
        data() {
            return {                
                                            
                salvandoDados :false,                
                documento:null,              
                listaTipos: [], 
                fileList:[],
                model: {                    
                    tipo: null,                                       
                    dataCadastro:null
                },                 
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            this.carregarStatus();

        },
        methods: {


         
            carregarStatus(){

                this.$http.get('/api/search/ListarTiposDocumentoAnexos')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.listaTipos = data;
                    }
                });                    

            },  
            
            enviarAnexos(){            

                if(this.documento != null){                        
                    this.fileList.push(this.documento);                    
                }                     
            },            
            
            salvarDados(){

                this.formData = new FormData(); 

                this.salvandoDados=true;

                this.enviarAnexos();
                                            
                
                this.formData.append("documento.Tipo", this.model.tipo);                
                this.formData.append("documento.DataHora", this.$moment(this.model.dataCadastro).format('YYYY-MM-DDTHH:mm:ss'));                            
                                
                for(let i = 0; i < this.fileList.length;i++){
                    
                    this.formData.append('files', this.fileList[i]);                                                                                                              
                }
                                                                                                                                   
                try {                                
                    this.$http.post(`/api/manutencao/DocumentoAnexo`,this.formData)
                    .then((res) => {
                        this.salvandoDados=false;
                        
                    }).finally(() => {                                
                        this.salvandoDados = false;    
                        this.$emit('loadData')                            
                        this.$emit('close');                                                                   
                    });                            

                    
                } catch (e) {
                    this.salvandoDados=false;                        
                    console.error(e);
                }

       
       
            },              
            async confirmar() {

                if(this.documento == null || this.model.tipo == null || this.model.dataCadastro == null   ){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('MODALINCLUIRDOCUMENTO.MENSAGEMCONCLUIDA'),
                        type: 'is-danger',
                        queue: false
                    });          
                    return false;          
                }  
                

                this.salvarDados();
                            
            },
            

        }
    }
</script>