<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div v-if="filtros.tipo!=0"  class="column is-5">
                <b-field>
                    <periodoHora :dataInicial.sync="filtros.datas[0]"
                    :dataFinal.sync="filtros.datas[1]"/>
                </b-field>
            </div>
            <div class="column is-3">
                                    
                <b-select expanded :placeholder="$t('FILTROS.TIPO')" v-model="filtros.tipo" style="width:100%;">
                     <option value="todos">Todos</option>
                        <option v-for="(item,index) in listaTipos" :key="index" :value="item.valor">
                            {{ item.descricao }}
                        </option>
                </b-select>
                    
            </div>              
            <div class="column is-1">
                <div class="buttons">
                    <b-button icon-left="magnify" type="is-primary" :loading="loading"
                    @click="loadAsyncData()">{{$t('SISTEMA.PROCURAR')}}</b-button> 
                                     
                </div>
            </div>
            <div class="column is-1">
                <div class="buttons">

                    <b-button expanded icon-left="plus" class="button is-info " 
                    @click="incluir()" > {{$t('SISTEMA.INCLUIR')}} </b-button>                                        
                </div>
            </div>            

        </div>     
        <b-table :data="model.lista"
                 :loading="loading"
                 :paginated="false"
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('SISTEMA.PROXPAGINA')"
                 :aria-previous-label="$t('SISTEMA.PAGANTERIOR')"
                 :aria-page-label="$t('SISTEMA.PAGINA')"
                 :aria-current-label="$t('SISTEMA.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template  @click="abrirCadastro(props.row.id)">

                <b-table-column v-slot="props" field="Data" :label="$t('SISTEMA.DATA')" >                    
                    {{ props.row.dataHora ?  $moment(props.row.data).format("DD/MM/YYYY") : '' }}
                </b-table-column>                
                <b-table-column v-slot="props" field="Tipo" :label="$t('SISTEMA.TIPO')" >
                    {{  retornarDescricaoTipo(props.row.tipo)   }}
                </b-table-column>
                <b-table-column v-slot="props" field="Nome" :label="$t('SISTEMA.NOME')" >                    
                    <a  @click="visualizarAnexo(props.row.id, props.row.fileName)" class="is-size-7 text-nowrap"><b>{{ props.row.fileName }}</b></a>
                </b-table-column>


                <b-table-column v-slot="props" custom-key="actions" numeric>
                                                                                                                                  
                    <b-button
                        icon-left="delete"
                        type="is-danger"
                        size="is-small"
                        :title="$t('ANEXOS.REMOVERANEXO')"
                        @click="removerAnexo(props.row)"></b-button>                                                                     
                                                                                        
                </b-table-column>                
                


            </template>
        </b-table>           

    </section>
</template>

<style scoped>

</style>

<script>
    import { mapGetters,mapState } from 'vuex'    
    import titlebar from '@/components/titlebar.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import modalIncluirDocumento from '@/components/manutencao/modalIncluirDocumento'
    import moment from 'moment';

    export default {
        data() {
            return {
                loading: false,
                listaTipos: [], 
                filtros: {
                    
                    tipo: 'todos',                                       
                    datas: [
                        this.$moment().subtract(1, 'months').startOf('month').toDate(),
                        this.$moment().toDate()
                    ]
                },   
                model:{
                    lista:[]
                },
                
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,                

            }
        },
        components: {
            titlebar,
            periodoHora
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),            
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('ADMINISTRATIVOLISTA.ADMINISTRATIVO'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },     
        methods: {

            removerAnexo(anexo) {
                
                this.$buefy.dialog.confirm({
                    title: this.$t('ANEXOS.EXCLUIRANEXO'),
                    message: this.$t('ANEXOS.CONFIRMAEXCLUSAO') + anexo.fileName.replace(/^.*[\\/]/, '') + '</b> ?',
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),                    
                    cancelText: this.$t('SISTEMA.CANCELAR'),                      
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {

                        const removerAnexo = {
                            tipo: "guia",
                            anexoId: anexo.id
                        }

                        this.isLoading = true;    
                        this.$http.post('/api/manutencao/ExcluirDocumentoAnexo', removerAnexo)
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadAsyncData()
                                }
                            }).finally(() => {                                
                                this.isLoading = false;                                  
                            });
                            
                    }
                })
            },                 
            visualizarAnexo(anexoId, fileName) {                
                window.open('/api/manutencao/DocumentoAnexoPdf?&anexoId=' + anexoId + '&fileName=' + fileName);
            },            
            retornarDescricaoTipo(tipo){
                if(tipo==0){
                    return 'Contrato';
                }else if (tipo==1){
                    return 'Boleto';
                }else if (tipo==2){
                    return 'Nota Fiscal';
                }
            },
            loadAsyncData() {        
                
                this.loading = true;
                const params = [];    
                
                if (this.filtros.tipo != 'todos')
                    params.push(`tipo=${this.filtros.tipo}`);   

                if(this.filtros.datas[0] !=null && this.filtros.datas[1] !=null ){
                    params.push(`dataInicial=${moment(this.filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss')}`); 
                    params.push(`dataFinal=${moment(this.filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`); 
                }    

                                
                this.$http.get(`/api/manutencao/DocumentosAnexo?${params.join('&')}`)
                .then((res) => {
                    if(res.data){
                        this.model.lista = res.data  
                    }                        
                }).finally(() => {                                
                    
                    this.loading = false;                                                                              
                }); 
            },                 
            incluir(){           
                this.$buefy.modal.open({
                    parent: this,
                    component: modalIncluirDocumento,

                    events: {
                        loadData: this.loadAsyncData,                        
                    },                    
                    hasModalCard: true,
                    trapFocus: true,                    
                })


            },
          
            carregarStatus(){

                this.$http.get('/api/search/ListarTiposDocumentoAnexos')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.listaTipos = data;
                    }
                });                    

            },            


        },
        mounted() {
            this.loadAsyncData();
            this.carregarStatus();            
            
        }
    }
</script>