<template>    
    <div class="modal-card">        
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('MODALUSUARIO.TITULO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless is-marginless">
            <b-loading :active.sync="isLoading"></b-loading>
            <div class="panel">
 
                <div>
                    <div class="panel-block">
                        <figure style="width: 150px; margin: 0 auto;" class="image">
                            <img :src="foto" ref="foto">
                        </figure>
                        <div class="file has-name fullwidth">
                            <label class="file-label fullwidth">
                                <input class="file-input" type="file" name="foto" ref="fileSele"  @change="imageFileSelected($event,'foto')">
                                <span class="file-cta ">
                                    <span class="file-label">
                                        <i class="mdi mdi-file"></i> {{$t('FUNCIONARIOCADASTRO.ARQUIVO')}}
                                    </span>
                                </span>                                            
                            </label>                                    
                        </div>                        
                    </div>


                </div>                

                <div class="panel-block">

                    <b-field label="Nova Senha" expanded class="fullwidth">
                            <b-input type="password" v-model="novaSenha" maxlength="40" :has-counter="false" expanded></b-input>
                    </b-field>                              
                    <b-button class="mt-3" icon-left="key" @click="alterarSenha()">
                        {{$t('MODALUSUARIO.ALTERARSENHA')}}
                    </b-button>
                </div>
                <div class="panel-heading">
                    <small>{{$t('MODALUSUARIO.LINKSUTEIS')}}</small>
                </div>
                <div class="panel-block">
                    <div class="buttons is-justified-between">
                        <b-button tag="a"
                                  :href="config.linkSuporteRemoto"
                                  target="_blank"
                                  icon-left="headset">
                            {{$t('MODALUSUARIO.SUPORTEREMOTO')}}
                        </b-button>
                        <b-button tag="a"
                                  :href="config.linkVideosTreinamento"
                                  target="_blank"
                                  icon-left="youtube">
                            {{$t('MODALUSUARIO.VIDEOSTREINAMENTO')}}
                        </b-button>
                        <b-button 
                                  @click="logout()"
                                  icon-left="logout">
                                  {{$t('SISTEMA.SAIR')}}
                        </b-button>                        
                    </div>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button is-pulled-right" type="button" @click="$parent.close()">{{$t('SISTEMA.FECHAR')}}</button>            
        </footer>
    </div>
</template>

<script>
    import { mapState,mapActions } from 'vuex'

    export default {

        data() {
            return {
                novaSenha: null,
                foto: null, 
                isLoading:false,  
            }
        },   
        async created() {
            this.novaSenha = null;
            await this.getImage();
        },     
        computed: {
            ...mapState([
                'config',
                'usuario'
            ]),
            ...mapActions([
                'logout'
            ]),            
        },
        methods:{

            salvarFuncionarioFoto(){

                const newItem = {
                    funcionario: {
                        id: this.usuario.funcionarioId
                    }
                }                   

                if (this.foto){
                    const dadosFotoArray = this.foto.split(',');
                    dadosFotoArray.shift();
                    newItem.foto = dadosFotoArray.join();
                }else{
                    newItem.foto = null;
                }

                this.$http.post(`/api/manutencao/salvarFuncionarioFoto`, newItem)
                    .then((res) => {
                        if(res.data != null && res.data.ok != null && res.data.ok == false){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.data.mensagem,
                                type: 'is-warning',
                                queue: false
                            })
                        }
                        this.$router.back()
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                    }).finally(() => {
                        this.isLoading = false; 
                    });                                     
            },         
            async imageFileSelected(e, itemName) {   
                this.isLoading = true;            
                const {name, size} = e.target.files[0];
                let imageData = null;
                if (FileReader && size) {
                    const fr = new FileReader();
                    fr.onload = () => {
                        this['fotoAlterada'+itemName] = true;                        

                        this.fotoAlteradaAvatar = true;
                        
                        imageData = fr.result;
                        this[itemName] = imageData;
                        if (this.$refs[itemName]) this.$refs[itemName].src = imageData;
                    }
                    fr.readAsDataURL(e.target.files[0]);
                }

                setTimeout(() => {this.salvarFuncionarioFoto()},5000);
            },            
            getImage() {
                if (!this.foto){                    
                    this.foto = '/api/manutencao/funcionarioFoto?id=' + this.usuario.funcionarioId+`&_cl=${new Date().getTime()}`;
                }

                return this.foto;
            },            
            alteracaoSenha(){


                const model = {novaSenha: this.novaSenha};

                this.$http.post(`/api/manutencao/FuncionarioAlterarSenha`, model )
                    .then((res) => {                        
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.data.mensagem,
                                type: 'is-success',
                                queue: false
                            });
                        
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        
                    });
                
                this.$parent.close()
            },
            alterarSenha(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('MODALUSUARIO.ALTERARSENHAMSG'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {
                        this.alteracaoSenha();
                    }
                })

            }            
        }
    }
</script>