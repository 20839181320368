import { render, staticRenderFns } from "./projetistas.vue?vue&type=template&id=681864f3&scoped=true&"
import script from "./projetistas.vue?vue&type=script&lang=js&"
export * from "./projetistas.vue?vue&type=script&lang=js&"
import style0 from "./projetistas.vue?vue&type=style&index=0&id=681864f3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681864f3",
  null
  
)

export default component.exports